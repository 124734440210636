<template>
  <div class="main-block">
    <div class="white-box action-page">
      <div class="action-page--heading gray">
        <div class="d-flex justify-content-between">
          <h1 class="action-page--heading__h d-flex set-flex-y-center">
            <span>{{ $t('shared.common.winks') }}</span>
            <span v-show="counters.winks_all" class="text-muted">({{ counters.winks_all }})</span>
            <span v-show="counters.winks_new" class="label label-primary">{{
              counters.winks_new
            }}</span>
          </h1>
          <div class="action-page--tip">
            <p class="d-none d-sm-block" @click="markViewed(false, true, true, true, true)">
              {{ $t('actionPages.utils.markAll') }}
            </p>
            <form
              class="action-page--search d-flex align-items-center"
              :class="{ open: searchState }"
              @submit.prevent=""
            >
              <div class="form-group">
                <input
                  v-model="searchValue"
                  type="text"
                  class="form-control"
                  :placeholder="$t('shared.placeholders.nameId')"
                  :autofocus="searchState"
                />
              </div>
              <button type="button" class="transparent" @click="searchState = !searchState">
                <span class="rbi rbi-search" />
              </button>
            </form>
          </div>
        </div>
      </div>
      <tabs
        cache-lifetime="0"
        :options="{ useUrlFragment: false }"
        @clicked="tabClicked"
        @changed="tabChanged"
      >
        <tab
          id="my"
          :name="
            tabsText.received() +
            (counters.winks_all && width >= 480 ? ' (' + counters.winks_all + ')' : '')
          "
        >
          <div class="action-page--grid">
            <div v-show="showMyPreloader" class="loader--wrap">
              <div class="loader" />
            </div>
            <p
              v-show="!displayedList.myWinks.length && isSearching && !showMyPreloader"
              class="alert alert-warning"
            >
              {{ $t('shared.utils.noResultMatch', [searchValue]) }}
            </p>
            <p
              v-show="!displayedList.myWinks.length && !isSearching && !showMyPreloader"
              class="alert alert-info"
            >
              {{ $t('actionPages.errors.noWinks') }}
            </p>
            <div v-if="!showMyPreloader" class="action-page--grid__inner users-block-flex">
              <app-user
                v-for="user in displayedList.myWinks"
                :key="user.user_id"
                :user="user"
                :disabled-props="user.disabled"
                :class="{ 'not-viewed': !user.seen }"
                @mouseenter.native="markViewed(user.user_id, false, !user.seen, user)"
              />
            </div>
            <div v-show="showLoadBtn.my && !showMyPreloader" class="button-wrap">
              <button type="button" class="btn btn-lazy-load btn-outline" @click="loadMore">
                <span class="d-flex align-items-center set-flex-x-center">
                  {{ $t('shared.btn.lazyLoad', [defaultLimit]) }}
                </span>
              </button>
            </div>
          </div>
        </tab>
        <tab
          id="me"
          :name="
            tabsText.sent() +
            (counters.winks_send && width >= 480 ? ' (' + counters.winks_send + ')' : '')
          "
        >
          <div class="action-page--grid">
            <div v-show="showIsentPreloader" class="loader--wrap">
              <div class="loader" />
            </div>
            <p
              v-show="!displayedList.iSent.length && isSearching && !showIsentPreloader"
              class="alert alert-warning"
            >
              {{ $t('shared.utils.noResultMatch', [searchValue]) }}
            </p>
            <p
              v-show="!displayedList.iSent.length && !isSearching && !showIsentPreloader"
              class="alert alert-info"
            >
              {{ $t('actionPages.errors.noSentWinks') }}
            </p>
            <div class="action-page--grid__inner users-block-flex">
              <app-user
                v-for="user in displayedList.iSent"
                :key="user.user_id"
                :disabled-props="user.disabled"
                :user="user"
              />
            </div>
            <div v-show="showLoadBtn.iSent && !showIsentPreloader" class="button-wrap">
              <button type="button" class="btn btn-lazy-load btn-outline" @click="loadMore">
                <span class="d-flex align-items-center set-flex-x-center">
                  {{ $t('shared.btn.lazyLoad', [defaultLimit]) }}
                </span>
              </button>
            </div>
          </div>
        </tab>
      </tabs>

      <!--      <div class="action-page&#45;&#45;content">
      <div class="tab-content">
      <div role="tabpanel" class="tab-pane active" id="my">
      <div class="action-page&#45;&#45;grid">
      <div class="loader&#45;&#45;wrap" v-show="showMyPreloader">
      <div class="loader"></div>
      </div>
      <p class="alert alert-warning"
      v-show="!displayedList.myWinks.length && isSearching && !showMyPreloader">
      {{ $t( 'shared.utils.noResultMatch', [ searchValue ] ) }}</p>
      <p class="alert alert-info"
      v-show="!displayedList.myWinks.length && !isSearching && !showMyPreloader">
      {{ $t( 'actionPages.errors.noWinks' ) }}</p>
      <div class="action-page&#45;&#45;grid__inner users-block-flex">
      <app-user v-for="user in displayedList.myWinks"
      :key="user.user_id"
      :user="user" :disabledProps="user.disabled"
      @mouseenter.native="markViewed(user.user_id, false, !user.seen, user)"
      :class="{'not-viewed': !user.seen}"></app-user>
      </div>
      </div>
      <button type="button"
      v-show="showLoadBtn.my && !showMyPreloader"
      @click="loadMore"
      class="btn btn-lazy-load btn btn-primary transparent">
      <span class="d-flex align-items-center set-flex-x-center">
      {{ $t( 'shared.btn.lazyLoad', [ defaultLimit ] ) }} <i class="fa fa-circle-o-notch fa-spin"
      aria-hidden="true"></i>
      </span>
      </button>
      </div>
      <div role="tabpanel" class="tab-pane" id="me">
      <div class="action-page&#45;&#45;grid users-block-flex">
      <div class="loader&#45;&#45;wrap" v-show="showIsentPreloader">
      <div class="loader"></div>
      </div>
      <p class="alert alert-warning"
      v-show="!displayedList.iSent.length && isSearching && !showIsentPreloader">
      {{ $t( 'shared.utils.noResultMatch', [ searchValue ] ) }}</p>
      <p class="alert alert-info"
      v-show="!displayedList.iSent.length && !isSearching && !showIsentPreloader">
      {{ $t( 'actionPages.errors.noSentWinks' ) }}</p>
      <div class="action-page&#45;&#45;grid__inner">
      <app-user v-for="user in displayedList.iSent"
      :key="user.user_id"
      :disabledProps="user.disabled"
      :user="user"></app-user>
      </div>
      </div>
      <button type="button"
      v-show="showLoadBtn.iSent && !showIsentPreloader"
      @click="loadMore"
      class="btn btn-lazy-load btn btn-primary transparent">
      <span class="d-flex align-items-center set-flex-x-center">
      {{ $t( 'shared.btn.lazyLoad', [ defaultLimit ] ) }} <i
      class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      </span>
      </button>
      </div>
      </div>
      </div>-->
      <app-slider v-if="!showIsentPreloader && !showMyPreloader" />
      <app-visited />
    </div>
    <app-preview-modal />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { PREPARE_DATA } from '../../mixins/userActions';
import UserPreview from '../Shared/UserPreview.vue';
import NewUsersSlider from '../Shared/parts/NewUsersSlider.vue';
import VisitedProfiles from '../Shared/parts/VisitedProfiles.vue';
import PreviewModal from '../Shared/modals/PreviewModal.vue';

export default {
  mixins: [PREPARE_DATA],
  data() {
    return {
      showMyPreloader: true,
      showIsentPreloader: true,
      defaultLimit: 20,
      limit: 20,
      searchBy: false, // false - myWinks / true - I sent
      searchValue: '',
      searchResults: {
        my: [],
        iSent: [],
      },
      searchState: false,
      isSearching: false,
    };
  },
  watch: {
    width(v) {
      if (v && v < 1200) {
        this.markViewed(false, true, true);
      }
    },
    searchBy() {
      this.$store.dispatch('getCounters');
      this.clearUpdate();
      this.limit = this.defaultLimit;
      this.extendedCheck(this.displayedList.myWinks, []);
      this.extendedCheck(this.displayedList.iSent, []);
    },
    searchValue(val) {
      this.limit = this.defaultLimit;
      this.clearUpdate();
      if (val.length) {
        this.searchResults.my = [];
        this.searchResults.iSent = [];
        this.search(val);
        this.isSearching = true;
        this.showMyPreloader = true;
        this.showIsentPreloader = true;
      } else {
        this.clearSearchResults();
        this.showMyPreloader = false;
        this.showIsentPreloader = false;
      }
    },
    displayedList(v, old) {
      this.extendedCheck(v.myWinks, old.myWinks);
      this.extendedCheck(v.iSent, old.iSent);
    },
  },
  methods: {
    tabClicked() {},
    tabChanged(selectedTab) {
      if (selectedTab.tab.id === 'me') {
        this.loadWinks(true);
      } else {
        this.loadWinks(false);
      }
    },
    search: debounce(function (val) {
      this.$http
        .post('v1/wink/search', {
          access_token: window.localStorage['access-token'],
          i_winked: this.searchBy || '',
          name_or_id: val,
        })
        .then((response) => {
          this.showMyPreloader = false;
          this.showIsentPreloader = false;
          this.searchBy
            ? (this.searchResults.iSent = response.body.result)
            : (this.searchResults.my = response.body.result);
        });
    }, 500),
    loadWinks(my) {
      this.searchBy = my;
      this.searchValue = '';
      this.clearSearchResults();

      this.$http
        .post('v1/wink/load', {
          access_token: window.localStorage['access-token'],
          i_winked: my || '',
        })
        .then(
          (response) => {
            if (my) {
              this.$store.commit('updateWinks', {
                offset: 0,
                type: 'iSent',
                data: response.body.result,
              });
            } else {
              this.$store.commit('updateWinks', {
                offset: 0,
                type: 'myWinks',
                data: response.body.result,
              });
            }
            this.showMyPreloader = false;
            this.showIsentPreloader = false;
          },
          (error) => {
            console.log(error);
          }
        );
    },
    clearSearchResults() {
      this.searchResults.my = [];
      this.searchResults.iSent = [];
      this.isSearching = false;
    },
    loadMore() {
      this.clearUpdate();
      this.limit += this.defaultLimit;
    },
    markViewed(single, all, condition, data, refresh) {
      if (condition) {
        this.$http
          .post('v1/wink/mark', {
            access_token: window.localStorage['access-token'],
            mark_all: all ? 1 : 0,
            target_user_id: single,
          })
          .then(() => {
            this.$store.dispatch('getCounters', {});
            if (single) {
              data.seen = +new Date();
            } else if (refresh) {
              this.winks.myWinks.forEach((i, index, arr) => {
                i.seen = 1;
                this.$set(arr, index, i);
              }, this);
            }
          });
      }
    },
  },
  computed: {
    tabsText() {
      const self = this;
      return {
        received() {
          if (self.width < 480) {
            return !self.searchBy
              ? self.$t('actionPages.titles.myWinks')
              : `${self.$t('actionPages.titles.myWinks').slice(0, 5)}...`;
          }
          return self.$t('actionPages.titles.myWinks');
        },
        sent() {
          if (self.width < 480) {
            return self.searchBy
              ? self.$t('actionPages.titles.winksIsent')
              : `${self.$t('actionPages.titles.winksIsent').slice(0, 5)}...`;
          }
          return self.$t('actionPages.titles.winksIsent');
        },
      };
    },
    width() {
      return this.$store.getters.deviceWidth;
    },
    winks() {
      return this.$store.getters.winks;
    },
    myWinks() {
      return this.winks.myWinks.slice(0, this.limit);
    },
    iSent() {
      return this.winks.iSent.slice(0, this.limit);
    },
    displayedList() {
      if (this.isSearching) {
        return {
          myWinks: this.limitedSearch.my,
          iSent: this.limitedSearch.iSent,
        };
      }
      return {
        myWinks: this.myWinks,
        iSent: this.iSent,
      };
    },
    limitedSearch() {
      return {
        my: this.searchResults.my.slice(0, this.limit),
        iSent: this.searchResults.iSent.slice(0, this.limit),
      };
    },
    counters() {
      return this.$store.getters.counters;
    },
    showLoadBtn() {
      return {
        my: this.isSearching
          ? this.displayedList.myWinks.length < this.searchResults.my
          : this.displayedList.myWinks.length < this.winks.myWinks.length,
        iSent: this.isSearching
          ? this.displayedList.iSent.length < this.searchResults.iSent
          : this.displayedList.iSent.length < this.winks.iSent.length,
      };
    },
  },
  components: {
    appUser: UserPreview,
    appSlider: NewUsersSlider,
    appVisited: VisitedProfiles,
    appPreviewModal: PreviewModal,
  },
  mounted() {
    if (this.width && this.width < 1200) {
      this.markViewed(false, true, true);
    }
    if (this.myWinks.length) {
      this.showMyPreloader = false;
      this.showIsentPreloader = false;
      this.extendedCheck(this.displayedList.myWinks, []);
      this.extendedCheck(this.displayedList.iSent, []);
    }
    document.title = this.$t('shared.common.winks');
    this.loadWinks(false);
    this.loadWinks(true);
    this.searchBy = false;
  },
};
</script>

<style lang="scss" scoped></style>
