<template>
  <div class="set-flex">
    <slot name="user" />
    <div class="news-box--item__data new">
      <div class="news-box--item__responsive">
        <slot name="responsive-user" />
        <slot name="time" />
      </div>
      <div class="news-box--item__data--new">
        <div class="news-box--item__data--new__profile">
          <div
            v-if="record.user.birthday !== null"
            class="item set-flex"
          >
            <p class="item--desc">
              {{ $t('profile.dateBirth') }}:
            </p>
            <p class="item--value">
              {{ date }}
            </p>
          </div>
          <div
            v-if="isData(record.user.family_status)"
            class="item set-flex"
          >
            <p class="item--desc">
              {{ $t('profile.marital') }}:
            </p>
            <p class="item--value">
              {{ isData(record.user.family_status) }}
            </p>
          </div>
          <div
            v-if="isData(record.user.lang_english)"
            class="item set-flex"
          >
            <p class="item--desc">
              {{ $t('profile.lvlEng') }}:
            </p>
            <p class="item--value">
              {{ isData(record.user.lang_english) }}
            </p>
          </div>
        </div>
        <router-link
          tag="button"
          :to="{ name: 'profile', params: { id: record.user.user_id } }"
          class="btn btn-outline-red"
        >
          <div class="set-flex">
            <span>{{ record.user.name }} {{ $t('actionPages.utils.joined') }} {{ $t('shared.common.projectName') }}</span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { AGE } from '../../../mixins/utils';

export default {
  components: {},
  mixins: [AGE],
  props: {
    record: {
      type: Object,
      required: true,
    },
    width: Number,
  },
  data() {
    return {};
  },
  computed: {
    /**
			 * Получить отображение времени
			 *
			 * @return {string}
			 */
    date() {
      if (this.record.user.birthday !== null) {
        return `${moment(this.record.user.birthday * 1000).format('LL')} (${this.getUserAge(this.record.user.birthday)} ${this.$t('profile.years')})`;
      }
      return this.$t('profile.noFill');
    },
  },
  methods: {

    /**
			 * Проверяет есть ли данные в записи
			 *
			 * @param data {string}
			 * @return {string}
			 */
    isData(data) {
      return data == 'no' ? this.$t('profile.noFill') : data;
    },
  },
};
</script>

<style>
</style>
