<template>
  <div class="main-block">
    <div class="white-box action-page">
      <div class="action-page--heading" :class="{ gray: sex.isMan }">
        <div class="set-flex set-flex-x-between">
          <h1 class="action-page--heading__h set-flex set-flex-y-center">
            <span>
              {{ $t('shared.common.favourites') }}
              <span v-show="counters.favorites_all" class="text-muted"
                >({{ counters.favorites_all }})</span
              >
            </span>
            <span
              v-show="sex.isMan ? counters.invites_all : counters.favorites_new"
              class="label label-primary"
              >{{ sex.isMan ? counters.invites_all : counters.favorites_new }}</span
            >
          </h1>
          <div class="action-page--tip">
            <p class="d-none d-sm-block" @click="markViewed(false, true, true, true, true)">
              {{ $t('actionPages.utils.markAll') }}
            </p>
            <form
              class="action-page--search set-flex set-flex-y-center"
              :class="{ open: searchState }"
              @submit.prevent=""
            >
              <div class="form-group">
                <input
                  v-model="searchValue"
                  type="text"
                  class="form-control"
                  :placeholder="$t('shared.placeholders.nameId')"
                  :autofocus="searchState"
                />
              </div>
              <button type="button" class="transparent" @click="searchState = !searchState">
                <span class="rbi rbi-search" />
              </button>
            </form>
          </div>
        </div>
      </div>
      <tabs
        v-if="sex.isMan"
        cache-lifetime="0"
        :options="{ useUrlFragment: false }"
        @clicked="tabClicked"
        @changed="tabChanged"
      >
        <tab
          id="my"
          :name="
            $t('actionPages.titles.myFavs') +
            (counters.favorites_all && width >= 480 ? ' (' + counters.favorites_all + ')' : '')
          "
        >
          <div class="action-page--grid">
            <div v-show="showPreloader" class="loader--wrap">
              <div class="loader" />
            </div>
            <p
              v-show="!displayedList.accepted.length && isSearching && !showPreloader"
              class="alert alert-warning"
            >
              {{ $t('shared.utils.noResultMatch', [searchValue]) }}
            </p>
            <p
              v-show="!displayedList.accepted.length && !isSearching && !showPreloader"
              class="alert alert-info"
            >
              {{ $t('actionPages.errors.noFavs') }}
            </p>
            <div class="action-page--grid__inner users-block-flex">
              <app-user
                v-for="user in displayedList.accepted"
                :key="user.user_id"
                :user="user"
                :disabled-props="user.disabled"
                :class="{ 'not-viewed': sex.isWoman ? !user.seen : false }"
                :with-delete="true"
                @mouseenter.native="markViewed(user.user_id, false, !user.seen, user)"
                @deleting="deleteFav($event.user_id)"
              />
            </div>
            <div v-show="showLoadBtn.accepted && !showPreloader" class="button-wrap">
              <button type="button" class="btn btn-lazy-load btn-outline" @click="loadMore">
                <span class="set-flex set-flex-y-center set-flex-x-center">
                  {{ $t('shared.btn.lazyLoad', [defaultLimit]) }}
                </span>
              </button>
            </div>
          </div>
        </tab>

        <tab id="me" :name="title">
          <div class="action-page--grid">
            <div v-show="showPreloader" class="loader--wrap">
              <div class="loader" />
            </div>
            <p
              v-show="!displayedList.followers.length && isSearching && !showPreloader"
              class="alert alert-warning"
            >
              {{ $t('shared.utils.noResultMatch', [searchValue]) }}
            </p>
            <p
              v-show="!displayedList.followers.length && !isSearching && !showPreloader"
              class="alert alert-info"
            >
              {{ $t('actionPages.errors.noFavsRequests') }}
            </p>
            <div class="action-page--grid__inner users-block-flex">
              <app-user
                v-for="user in displayedList.followers"
                :key="user.user_id"
                :disabled-props="user.disabled"
                :class="{ 'not-viewed': !user.seen }"
                :with-favs="true"
                :user="user"
                @mouseenter.native="markViewed(user.user_id, false, !user.seen, user)"
                @accept="loadFavs(false)"
                @reject="loadFavs(false)"
              />
            </div>
            <div v-show="showLoadBtn.followers && !showPreloader" class="button-wrap">
              <button type="button" class="btn btn-lazy-load btn-outline" @click="loadMore">
                <span class="set-flex set-flex-y-center set-flex-x-center">
                  {{ $t('shared.btn.lazyLoad', [defaultLimit]) }}
                </span>
              </button>
            </div>
          </div>
        </tab>
      </tabs>
      <div v-else>
        <div class="action-page--grid">
          <div v-show="showPreloader" class="loader--wrap">
            <div class="loader" />
          </div>
          <p
            v-show="!displayedList.accepted.length && isSearching && !showPreloader"
            class="alert alert-warning"
          >
            {{ $t('shared.utils.noResultMatch', [searchValue]) }}
          </p>
          <p
            v-show="!displayedList.accepted.length && !isSearching && !showPreloader"
            class="alert alert-info"
          >
            {{ $t('actionPages.errors.noFavs') }}
          </p>
          <div class="action-page--grid__inner users-block-flex">
            <app-user
              v-for="user in displayedList.accepted"
              :key="user.user_id"
              :user="user"
              :disabled-props="user.disabled"
              :class="{ 'not-viewed': sex.isWoman ? !user.seen : false }"
              :with-delete="true"
              @mouseenter.native="markViewed(user.user_id, false, !user.seen, user)"
              @deleting="deleteFav($event.user_id)"
            />
          </div>
          <div class="button-wrap">
            <button
              v-show="showLoadBtn.accepted && !showPreloader"
              type="button"
              class="btn btn-lazy-load btn btn-primary transparent"
              @click="loadMore"
            >
              <span class="set-flex set-flex-y-center set-flex-x-center">
                {{ $t('shared.btn.lazyLoad', [defaultLimit]) }}
                <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true" />
              </span>
            </button>
          </div>
        </div>
      </div>
      <!--			<div class="action-page&#45;&#45;content">
                      <div class="tab-content">
                          <div role="tabpanel" class="tab-pane" :class="{active: searchBy}" id="my">
                              <div class="action-page&#45;&#45;grid">
                                  <div class="loader&#45;&#45;wrap" v-show="showPreloader">
                                      <div class="loader"></div>
                                  </div>
                                  <p class="alert alert-warning"
                                     v-show="!displayedList.accepted.length && isSearching && !showPreloader">
                                      {{ $t( 'shared.utils.noResultMatch', [ searchValue ] ) }}</p>
                                  <p class="alert alert-info"
                                     v-show="!displayedList.accepted.length && !isSearching && !showPreloader">
                                      {{ $t( 'actionPages.errors.noFavs' ) }}</p>
                                  <div class="action-page&#45;&#45;grid__inner users-block-flex">
                                      <app-user v-for="user in displayedList.accepted"
                                                :user="user" :disabledProps="user.disabled"
                                                :key="user.user_id" :class="{'not-viewed': sex.isWoman ? !user.seen : false}"
                                                :withDelete="true"
                                                @mouseenter.native="markViewed(user.user_id, false, !user.seen, user)"
                                                @deleting="deleteFav($event.user_id)"></app-user>
                                  </div>
                              </div>
                              <button type="button"
                                      v-show="showLoadBtn.accepted  && !showPreloader"
                                      @click="loadMore"
                                      class="btn btn-lazy-load btn btn-primary transparent">
                                  <span class="set-flex set-flex-y-center set-flex-x-center">
                                      {{ $t( 'shared.btn.lazyLoad', [ defaultLimit ] ) }} <i
                                          class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                                  </span>
                              </button>
                          </div>
                          <div role="tabpanel" class="tab-pane" :class="{active: !searchBy}" id="me">
                              <div class="action-page&#45;&#45;grid">
                                  <div class="loader&#45;&#45;wrap" v-show="showPreloader">
                                      <div class="loader"></div>
                                  </div>
                                  <p class="alert alert-warning"
                                     v-show="!displayedList.followers.length && isSearching && !showPreloader">
                                      {{ $t( 'shared.utils.noResultMatch', [ searchValue ] ) }}</p>
                                  <p class="alert alert-info"
                                     v-show="!displayedList.followers.length && !isSearching && !showPreloader">{{
                                      $t( 'actionPages.errors.noFavsRequests' ) }}</p>
                                  <div class="action-page&#45;&#45;grid__inner">
                                      <app-user v-for="user in displayedList.followers"
                                                :key="user.user_id" :disabledProps="user.disabled"
                                                @mouseenter.native="markViewed(user.user_id, false, !user.seen, user)"
                                                :class="{'not-viewed': !user.seen}"
                                                :withFavs="true"
                                                :user="user"></app-user>
                                  </div>
                              </div>
                              <button type="button"
                                      v-show="showLoadBtn.followers && !showPreloader"
                                      @click="loadMore"
                                      class="btn btn-lazy-load btn btn-primary transparent">
                              <span class="set-flex set-flex-y-center set-flex-x-center">
                                  {{ $t( 'shared.btn.lazyLoad', [ defaultLimit ] ) }} <i
                                      class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                              </span>
                              </button>
                          </div>
                      </div>
                  </div>-->
      <app-slider v-if="!showPreloader" />
      <app-visited />
    </div>

    <app-preview-modal />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { PREPARE_DATA } from '../../mixins/userActions';
import UserPreview from '../Shared/UserPreview.vue';
import NewUsersSlider from '../Shared/parts/NewUsersSlider.vue';
import VisitedProfiles from '../Shared/parts/VisitedProfiles.vue';
import PreviewModal from '../Shared/modals/PreviewModal.vue';

export default {
  mixins: [PREPARE_DATA],
  data() {
    return {
      showPreloader: true,
      defaultLimit: 20,
      limit: 20,
      searchBy: true, // true - accepted / false - followers
      searchValue: '',
      isSearching: false,
      searchResults: {
        my: [],
        followers: [],
      },
      searchState: false,
    };
  },
  watch: {
    /**
     * Отметить все записи просмотренные в случае, если пользователь зашел с моб. устройства
     * @param v
     */
    width(v) {
      if (v && v < 1200) {
        this.markViewed(false, true, true);
      }
    },
    /**
     * Запустить проверку привилегий взаимодействия с пользователями
     * @param v {array}
     * @param old {array}
     */
    displayedList(v, old) {
      this.extendedCheck(v.accepted, old.accepted);
      this.extendedCheck(v.followers, old.followers);
    },

    /**
     * Обновление состояние компонента при переключении между вкладками
     *
     * @param val {string}
     */
    searchBy() {
      this.clearUpdate();
      this.$store.dispatch('getCounters');
      this.limit = this.defaultLimit;
      this.extendedCheck(this.displayedList.accepted, this.displayedList.accepted);
      this.extendedCheck(this.displayedList.followers, this.displayedList.followers);
    },

    /**
     * Поиск по имени или ИД пользователя
     *
     * @param val {string}
     */
    searchValue(val) {
      this.limit = this.defaultLimit;
      this.clearUpdate();
      this.clearSearchResults();
      if (val.length) {
        this.isSearching = true;
        this.showPreloader = true;
        if (this.searchBy) {
          this.searchAccepted(val);
        } else {
          this.searchFollowers(val);
        }
      } else {
        this.isSearching = false;
        this.showPreloader = false;
      }
    },
  },
  methods: {
    tabClicked() {},
    tabChanged(selectedTab) {
      if (selectedTab.tab.id === 'my') {
        this.loadFavs(true);
      } else {
        this.loadFavs(false);
      }
    },
    /**
     * Поиск среди утвержденных фаворитов
     *
     * @param val {string}
     */
    searchAccepted: debounce(function (val) {
      this.$http
        .post('v1/favorite/load-accept', {
          access_token: window.localStorage['access-token'],
          name_or_id: val,
        })
        .then((response) => {
          this.showPreloader = false;
          this.searchResults.my = response.body.result;
        });
    }, 500),

    /**
     * Поиск среди подписчиков
     *
     * @param val {string}
     */
    searchFollowers: debounce(function (val) {
      this.$http
        .post('v1/favorite/load-follower', {
          access_token: window.localStorage['access-token'],
          name_or_id: val,
        })
        .then(
          (response) => {
            this.showPreloader = false;
            this.searchResults.followers = response.body.result;
          },
          (error) => {
            console.log(error);
          }
        );
    }),

    /**
     * Загрузить фаворитов в зависимости от вкладки
     * @param my {boolean} true - accepted / false - followers
     */
    loadFavs(my) {
      this.searchBy = my;
      this.searchValue = '';
      if (my) {
        this.$http
          .post('v1/favorite/load-accept', {
            access_token: window.localStorage['access-token'],
          })
          .then(
            (response) => {
              this.clearUpdate();
              this.showPreloader = false;
              this.$store.commit('updateFavorites', {
                type: 'accepted',
                data: response.body.result,
              });
            },
            (error) => {
              console.log(error);
            }
          );
      } else {
        this.$http
          .post('v1/favorite/load-follower', {
            access_token: window.localStorage['access-token'],
          })
          .then(
            (response) => {
              this.clearUpdate();
              this.$store.dispatch('getCounters');
              this.showPreloader = false;
              this.$store.commit('updateFavorites', {
                type: 'followers',
                data: response.body.result,
              });
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },

    /**
     * Очистить результаты поиска
     */
    clearSearchResults() {
      this.searchResults.my = [];
      this.searchResults.followers = [];
    },

    /**
     * Показать больше записей на вкладке
     */
    loadMore() {
      this.clearUpdate();
      this.limit += this.defaultLimit;
    },

    /**
     * Отметить как просмотренные
     *
     * @param single {id | boolean} - данные для отметки одного пользователя
     * @param all {boolean} - отметить ли всех
     * @param condition {boolean} - условие, при котором выполнение продолжится
     * @param data {object} - объект с записью
     * @param refresh {boolean} нужно ли обновить данные после отправки
     */
    markViewed(single, all, condition, data, refresh) {
      if (condition) {
        this.$http
          .post('v1/favorite/make-seen', {
            access_token: window.localStorage['access-token'],
            mark_all: all ? 1 : 0,
            favorite_user_id: single || '',
            type: this.searchBy ? 'confirmed' : 'invite',
          })
          .then(() => {
            this.$store.dispatch('getCounters', {});
            if (single) {
              data.seen = +new Date();
            } else if (refresh) {
              if (this.searchBy) {
                this.favorites.accepted.forEach((i, index, arr) => {
                  i.seen = 1;
                  this.$set(arr, index, i);
                }, this);
              } else {
                this.favorites.followers.forEach((i, index, arr) => {
                  i.seen = 1;
                  this.$set(arr, index, i);
                }, this);
              }
            }
          });
      }
    },

    /**
     * Удалить пользователя из фаворитов
     *
     * @param target
     */
    deleteFav(target) {
      this.$http
        .post('v1/favorite/delete', {
          access_token: window.localStorage['access-token'],
          favorite_user_id: target,
        })
        .then(
          () => {},
          (err) => {
            console.error(err);
          }
        );
    },
  },
  computed: {
    title() {
      return `${this.$t('actionPages.titles.followers')}
        ${
          this.sex.isMan
            ? this.counters.invites_all
              ? `<span class="label label-primary">${
                  this.sex.isMan ? this.counters.invites_all : this.counters.favorites_new
                }</span>`
              : ''
            : this.counters.favorites_new
            ? `<span class="label label-primary">${
                this.sex.isMan ? this.counters.invites_all : this.counters.favorites_new
              }</span>`
            : ''
        }`;
    },

    width() {
      return this.$store.getters.deviceWidth;
    },
    sex() {
      return this.$store.getters.sex;
    },
    favorites() {
      return this.$store.getters.favorites;
    },
    /**
     * Получить ограниченный список для утвержденных фаворитов
     */
    accepted() {
      return this.favorites.accepted.slice(0, this.limit);
    },
    /**
     * Получить ограниченный список для подписчиков
     */
    followers() {
      return this.favorites.followers.slice(0, this.limit);
    },
    counters() {
      return this.$store.getters.counters;
    },

    /**
     * Отображать нужный список в зависимости от поиска: результаты или весь
     *
     * @return {*}
     */
    displayedList() {
      if (this.isSearching) {
        return {
          accepted: this.limitedSearch.my,
          followers: this.limitedSearch.followers,
        };
      }
      return {
        accepted: this.accepted,
        followers: this.followers,
      };
    },

    /**
     * Ограничить показываемые результаты поиска
     *
     * @return {{my: array, followers: array}}
     */
    limitedSearch() {
      return {
        my: this.searchResults.my.slice(0, this.limit),
        followers: this.searchResults.followers.slice(0, this.limit),
      };
    },

    /**
     * Показывать ли кнопку "загрузить еще" для каждой из вкладок
     * @return {{accepted: boolean, followers: boolean}}
     */
    showLoadBtn() {
      return {
        accepted: this.isSearching
          ? this.displayedList.accepted.length < this.searchResults.my.length
          : this.displayedList.accepted.length < this.counters.favorites_all,
        followers: this.isSearching
          ? this.displayedList.followers.length < this.searchResults.followers.length
          : this.displayedList.followers.length < this.favorites.followers.length,
      };
    },
  },
  components: {
    appUser: UserPreview,
    appSlider: NewUsersSlider,
    appVisited: VisitedProfiles,
    appPreviewModal: PreviewModal,
  },
  mounted() {
    if (this.width && this.width < 1200) {
      this.markViewed(false, true, true);
    }
    if (this.accepted.length) {
      this.showPreloader = false;
      this.extendedCheck(this.displayedList.accepted, this.displayedList.accepted);
    }

    document.title = this.$t('shared.common.favourites');
    this.loadFavs(true);
    this.loadFavs(false);
    this.searchBy = !this.counters.invites_all;
    //			this.searchBy = true;
  },
};
</script>

<style></style>
