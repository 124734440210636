<template>
  <div class=" btn btn-outline btn-lazy-load">
    {{ $t( 'shared.btn.lazyLoad', [ counter ] ) }}
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    counter: Number,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
	.load-more {
    margin-top: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
