<template>
  <div class="main-block">
    <div class="white-box action-page">
      <div class="action-page--heading">
        <div class="set-flex set-flex-x-between">
          <h1 class="action-page--heading__h set-flex set-flex-y-center">
            <span>
              {{ $t('shared.common.guests') }}
              <span v-show="counters.guests_all" class="text-muted"
                >({{ counters.guests_all }})</span
              >
            </span>
            <span v-show="counters.guests_new" class="label label-primary">{{
              counters.guests_new
            }}</span>
          </h1>
          <div class="action-page--tip">
            <p class="d-none d-sm-block" @click="markViewed(false, true, true, true, true)">
              {{ $t('actionPages.utils.markAll') }}
            </p>
            <form
              class="action-page--search set-flex set-flex-y-center"
              :class="{ open: searchState }"
              @submit.prevent=""
            >
              <div class="form-group">
                <input
                  v-model="searchValue"
                  type="text"
                  class="form-control"
                  :placeholder="$t('shared.placeholders.nameId')"
                  :autofocus="searchState"
                />
              </div>
              <button type="button" class="transparent" @click="searchState = !searchState">
                <span class="rbi rbi-search" />
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="action-page--content">
        <div class="tab-pane">
          <div class="action-page--grid">
            <p
              v-show="!displayedList.length && isSearching && !showPreloader"
              class="alert alert-warning"
            >
              {{ $t('shared.utils.noResultMatch', [searchValue]) }}
            </p>
            <p
              v-if="!displayedList.length && !isSearching && !showPreloader"
              class="alert alert-info"
            >
              {{ $t('actionPages.errors.noGuests') }}
            </p>
            <div v-show="showPreloader" class="loader--wrap">
              <div class="loader" />
            </div>
            <div class="action-page--grid__inner users-block-flex">
              <app-user
                v-for="user in displayedList"
                :key="user.user_id"
                :disabled-props="user.disabled"
                :class="{ 'not-viewed': !user.seen }"
                :user="user"
                @mouseenter.native="markViewed(user.user_id, false, !user.seen, user)"
              />
            </div>
            <div v-show="showLoadBtn && !showPreloader" class="button-wrap">
              <button type="button" class="btn btn-lazy-load btn-outline" @click="loadMore">
                <span class="set-flex set-flex-y-center set-flex-x-center">
                  {{ $t('shared.btn.lazyLoad', [limit]) }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <app-slider />
      <app-visited />
    </div>
    <app-preview-modal />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { PREPARE_DATA } from '../../mixins/userActions';
import UserPreview from '../Shared/UserPreview.vue';
import NewUsersSlider from '../Shared/parts/NewUsersSlider.vue';
import VisitedProfiles from '../Shared/parts/VisitedProfiles.vue';
import PreviewModal from '../Shared/modals/PreviewModal.vue';

export default {
  mixins: [PREPARE_DATA],
  data() {
    return {
      isSearching: false,
      showPreloader: true,
      limit: 20,
      offset: 20,
      searchValue: '',
      searchResults: [],
      searchState: false,
    };
  },
  watch: {
    /**
     * Отметить все записи просмотренными, если пользователь зашёл с моб. устройства
     *
     * @param v {number}
     */
    width(v) {
      if (v && v < 1200) {
        this.markViewed(false, true, true);
      }
    },

    /**
     * Поиск по имени или ИД пользователя
     *
     * @param val {string}
     */
    searchValue(val) {
      this.clearUpdate();
      this.searchResults = [];
      this.offset = this.limit;
      if (val.length) {
        this.isSearching = true;
        this.showPreloader = true;
        val += '';
        this.search(val);
      } else {
        this.isSearching = false;
        this.showPreloader = false;
      }
    },

    /**
     * Проверка привилегий взаимодействия с пользователями
     *
     * @param v {array}
     * @param old {array}
     */
    displayedList(v, old) {
      this.extendedCheck(v, old);
    },
  },
  methods: {
    /**
     * Загрузить новый список данных после задержки в 500мс
     *
     * @param val {string}
     */
    search: debounce(function (val) {
      this.$http
        .post('v1/guest/search', {
          access_token: window.localStorage['access-token'],
          name_or_id: val,
        })
        .then((response) => {
          this.showPreloader = false;
          this.searchResults.splice(0, this.searchResults.length);
          this.searchResults = response.body.result;
        });
    }, 500),

    /**
     * Загрузить список гостей
     */
    loadGuests() {
      this.$http
        .post('v1/guest/load', {
          access_token: window.localStorage['access-token'],
          i_visited: 0,
        })
        .then(
          (response) => {
            this.showPreloader = false;
            this.$store.commit('updateGuests', {
              offset: 0,
              data: response.body.result,
            });
          },
          (error) => console.log(error)
        );
    },

    /**
     * Показать больше записей
     */
    loadMore() {
      this.offset += this.limit;
    },

    /**
     * Отметить как просмотренные
     *
     * @param single {id | boolean} - данные для отметки одного пользователя
     * @param all {boolean} - отметить ли всех
     * @param condition {boolean} - условие, при котором выполнение продолжится
     * @param data {object} - объект с записью
     * @param refresh {boolean} нужно ли обновить данные после отправки
     */
    markViewed(single, all, condition, data, refresh) {
      if (condition) {
        this.$http
          .post('v1/guest/mark', {
            access_token: window.localStorage['access-token'],
            mark_all: all ? 1 : 0,
            target_user_id: single || 0,
          })
          .then(() => {
            this.$store.dispatch('getCounters', {});
            if (single) {
              data.seen = +new Date();
            } else if (refresh) {
              this.guests.forEach((i, index, arr) => {
                i.seen = 1;
                this.$set(arr, index, i);
              }, this);
            }
          });
      }
    },
  },
  computed: {
    width() {
      return this.$store.getters.deviceWidth;
    },
    guests() {
      return this.$store.getters.guests;
    },

    /**
     * Получить ограниченное кол-во записей результатов поиска
     *
     * @return {array}
     */
    limitedSearch() {
      return this.searchResults.slice(0, this.offset);
    },

    /**
     * Получить ограниченное кол-во гостей
     *
     * @return {array}
     */
    limitedGuests() {
      return this.guests.slice(0, this.offset);
    },
    counters() {
      return this.$store.getters.counters;
    },

    /**
     * Выбрать какой именно список мы сейчас показываем: поиск или обычный
     *
     * @return {array}
     */
    displayedList() {
      return this.isSearching ? this.limitedSearch : this.limitedGuests;
    },

    /**
     * Показать или спрятать кнопку "загрузить еще"
     * @return {boolean}
     */
    showLoadBtn() {
      if (this.isSearching) {
        return this.displayedList.length < this.searchResults.length;
      }
      return this.displayedList.length < this.counters.guests_all;
    },
  },
  components: {
    appUser: UserPreview,
    appSlider: NewUsersSlider,
    appVisited: VisitedProfiles,
    appPreviewModal: PreviewModal,
  },
  mounted() {
    if (this.width && this.width < 1200) {
      this.markViewed(false, true, true);
    }
    if (this.guests.length) {
      this.showPreloader = false;
      this.extendedCheck(this.limitedGuests, []);
    }
    document.title = this.$t('shared.common.guests');
    this.loadGuests();
  },
};
</script>

<style></style>
