<template>
  <div class="news-box--item__inner">
    <component
      :is="record.type"
      :record="record"
      :width="width"
    >
      <user-preview
        slot="user"
        :user="record.user"
        class="d-none d-md-none d-lg-flex"
      />
      <user-responsive
        slot="responsive-user"
        :user="record.user"
      />
      <p
        slot="time"
        class="news-box--item__data--time"
      >
        {{ time(record.time) }}
      </p>
    </component>
  </div>
</template>

<script>

import moment from 'moment';
import UserPreview from '../../Shared/UserPreview.vue';
import UserResponsive from './ResponsiveUser.vue';
import PhotoTemplate from './PhotoTemplate.vue';
import NewUser from './NewUserTemplate.vue';

export default {
  components: {
    userPreview: UserPreview,
    photo: PhotoTemplate,
    new_user_man: NewUser,
    new_user_woman: NewUser,
    UserResponsive,
  },
  props: {
    record: Object,
    width: Number,
  },
  data() {
    return {};
  },
  methods: {

    /**
			 * Получить отображение времени в зависимости от таймстемпа
			 * @param timestamp {number}
			 * @return {string}
			 */
    time(timestamp) {
      return moment(timestamp * 1000).calendar(null, {
        sameDay: '[Today at] LT',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday at] LT',
        lastWeek: '[Last] dddd',
        sameElse: 'MMM Do, YYYY LT',
      });
    },
  },
};
</script>

<style>
</style>
