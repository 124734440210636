<template>
  <div class="main-block">
    <div class="white-box action-page">
      <div class="action-page--heading">
        <div class="set-flex set-flex-x-between">
          <h1 class="action-page--heading__h set-flex set-flex-y-center">
            <span>
              {{ $t('shared.common.bookmarks') }}
              <span v-show="counters.bookmarks_all" class="text-muted"
                >({{ counters.bookmarks_all }})</span
              >
            </span>
            <!--<span class="label label-primary" v-show="counters.bookmarks_new">{{ counters.bookmarks_new-->
            <!--}}</span>-->
          </h1>
          <div class="action-page--tip">
            <form
              class="action-page--search set-flex set-flex-y-center"
              :class="{ open: searchState }"
              @submit.prevent=""
            >
              <div class="form-group">
                <input
                  v-model="searchValue"
                  type="text"
                  class="form-control"
                  :placeholder="$t('shared.placeholders.nameId')"
                  :autofocus="searchState"
                />
              </div>
              <button type="button" class="transparent" @click="searchState = !searchState">
                <span class="rbi rbi-search" />
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="action-page--content">
        <div class="tab-pane">
          <div class="action-page--grid">
            <div v-show="showPreloader && !showLoadBtn" class="loader--wrap">
              <div class="loader" />
            </div>
            <p
              v-show="!displayedList.length && !isSearching && !showPreloader"
              class="alert alert-info"
            >
              {{ $t('actionPages.errors.noBookmarks') }}
            </p>
            <p
              v-show="!displayedList.length && isSearching && !showPreloader"
              class="alert alert-warning"
            >
              {{ $t('shared.utils.noResultMatch', [searchValue]) }}
            </p>
            <div class="action-page--grid__inner users-block-flex">
              <app-user
                v-for="user in displayedList"
                :key="user.user_id"
                :disabled-props="user.disabled"
                :with-delete="true"
                :user="user"
                @deleting="deleting($event)"
              />
            </div>
            <div v-show="showLoadBtn && !showPreloader" class="button-wrap">
              <button type="button" class="btn btn-lazy-load btn-outline" @click="loadMore(true)">
                <span class="set-flex set-flex-y-center set-flex-x-center">
                  {{ $t('shared.btn.lazyLoad', [defaultLimit]) }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <app-slider />
      <app-visited />
    </div>
    <app-preview-modal />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { PREPARE_DATA } from '../../mixins/userActions';
import UserPreview from '../Shared/UserPreview.vue';
import NewUsersSlider from '../Shared/parts/NewUsersSlider.vue';
import VisitedProfiles from '../Shared/parts/VisitedProfiles.vue';
import PreviewModal from '../Shared/modals/PreviewModal.vue';

export default {
  mixins: [PREPARE_DATA],
  data() {
    return {
      showPreloader: true,
      defaultLimit: 20,
      limit: 20,
      isSearching: false,
      searchValue: '',
      searchResults: [],
      searchState: false,
    };
  },
  watch: {
    /**
     * Поиск по имени или ИД пользователя
     *
     * @param val {string}
     */
    searchValue(val) {
      this.clearUpdate();
      this.limit = this.defaultLimit;
      this.searchResults = [];
      if (val.length) {
        this.isSearching = true;
        this.showPreloader = true;
        this.search(val);
      } else {
        this.isSearching = false;
        this.showPreloader = false;
      }
    },

    /**
     * Проверка привилегий взаимодействия с пользователями
     *
     * @param v {array}
     * @param old {array}
     */
    displayedList(v, old) {
      this.extendedCheck(v, old);
    },
  },
  methods: {
    /**
     * Загрузить новый список данных после задержки в 500мс
     *
     * @param val {string}
     */
    search: debounce(function (val) {
      this.$http
        .post('v1/bookmark/load', {
          access_token: window.localStorage['access-token'],
          name_or_id: val,
        })
        .then((response) => {
          this.showPreloader = false;
          this.searchResults.splice(0, this.searchResults.length);
          this.searchResults = response.body.result;
        });
    }, 500),

    /**
     * Показать больше записей
     */
    loadMore() {
      this.clearUpdate();
      this.limit += this.defaultLimit;
      this.loadBookmarks();
    },

    /**
     * Удалить закладку
     * @param id {number}
     */
    deleting(id) {
      this.$http.post('v1/bookmark/delete', {
        access_token: window.localStorage['access-token'],
        bookmark_user_id: id.user_id,
      });
    },

    /**
     * Загрузить закладки
     */
    loadBookmarks() {
      this.showPreloader = true;
      this.clearUpdate();
      this.$http
        .post('v1/bookmark/load', {
          access_token: window.localStorage['access-token'],
          offset: 0,
          limit: this.limit,
        })
        .then(
          (response) => {
            this.clearUpdate();
            this.showPreloader = false;
            this.$store.commit('updateBookmarks', {
              offset: 0,
              data: response.body.result,
            });
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
  computed: {
    bookmarks() {
      return this.$store.getters.bookmarks;
    },
    /**
     * Получить ограниченное кол-во результатов поиска
     *
     * @return {array}
     */
    searchLimited() {
      return this.searchResults.slice(0, this.limit);
    },

    /**
     * Получить ограниченное кол-во закладок
     *
     * @return {array}
     */
    limitedBookmarks() {
      return this.bookmarks.slice(0, this.limit);
    },

    /**
     * Выбрать какой именно список мы сейчас показываем: поиск или обычный
     * @return {array}
     */
    displayedList() {
      return this.isSearching ? this.searchLimited : this.limitedBookmarks;
    },
    counters() {
      return this.$store.getters.counters;
    },

    /**
     * Показать или спрятать кнопку "загрузить еще"
     *
     * @return {boolean}
     */
    showLoadBtn() {
      if (this.isSearching) {
        return this.displayedList.length < this.searchResults.length;
      }
      return this.displayedList.length < this.counters.bookmarks_all;
    },
  },
  components: {
    appUser: UserPreview,
    appSlider: NewUsersSlider,
    appVisited: VisitedProfiles,
    appPreviewModal: PreviewModal,
  },
  mounted() {
    if (this.bookmarks.length) {
      this.showPreloader = false;
      this.extendedCheck(this.limitedBookmarks, []);
    }
    document.title = this.$t('shared.common.bookmarks');
    this.loadBookmarks();
  },
};
</script>

<style></style>
