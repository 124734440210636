<template>
  <div
    v-show="users.length"
    class="visited-profiles"
  >
    <div class="contain-edit">
      <h2 class="title-profile edit-caption">
        {{ $t( 'shared.headers.wantChat' ) }}
      </h2>
    </div>
    <div class="flexblock">
      <app-user-shortened
        v-for="user in users"
        :key="user.user_id"
        :user="user"
      />
    </div>
  </div>
</template>

<script>
import UserShortened from './UserShortened.vue';

export default {
  components: {
    appUserShortened: UserShortened,
  },
  data() {
    return {
      asked: false,
    };
  },
  computed: {
    users() {
      return this.$store.getters.randomUsers;
    },
    sex() {
      return this.$store.getters.sex;
    },
  },
  watch: {
    /**
			 * спросить данные, когда свой профиль был загружен, если они еще не были запрошены
			 */
    sex() {
      if (!this.asked) this.askData();
    },
  },
  mounted() {
    this.asked = false;
    this.askData();
  },
  methods: {
    /**
			 * запросить девушек онлайн
			 *
			 * @param limit {number} кол-во записей для получения
			 */
    askWomen(limit) {
      this.$http.post('v1/user-guest/online-woman', {
        limit,
      }).then((response) => {
        if (response.body.status) {
          this.$store.commit('setRandomUsers', response.body.result.list);
        }
      });
    },
    /**
			 * запросить мужчин онлайн
			 *
			 * @param limit {number} кол-во записей для получения
			 */
    askMen(limit) {
      this.$http.post('v1/profile/search', {
        access_token: window.localStorage['access-token'],
        limit,
        is_online: 1,
      }).then((response) => {
        if (response.body.status) {
          this.$store.commit('setRandomUsers', response.body.result);
        }
      });
    },
    /**
			 * запрос данных пользователей в зависимости от пола
			 */
    askData() {
      if (this.sex.isWoman) {
        this.askMen(16);
        this.asked = true;
      } else if (this.sex.isMan || !window.localStorage['access-token']) {
        this.askWomen(16);
        this.asked = true;
      }
    },
  },
};
</script>

<style>
</style>
