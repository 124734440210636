<template>
  <div
    class="link"
    @click="openPreviewModal(user.user_id, true)"
  >
    <router-link
      v-if="sex.isWoman"
      :to="{ name: 'profile', params: { id: this.user.user_id } }"
    >
      <div
        v-background="{img: user.avatar.img_medium, imgClass: 'obj-fit-bg'}"
        class="user-shortened"
      >
        <img
          :src="user.avatar.img_medium"
          class="obj-fit-bg"
          :alt="user.name + $t('shared.alts.ava')"
        >
        <span
          v-if="user.online"
          class="online"
        />
        <span
          v-if="userWebcam(user.camera).status"
          class="webcam rbi-webcam"
          :class="{hd: userWebcam(user.camera).hd}"
        />
        <span
          v-if="user.new"
          class="is-new"
        />
      </div>
      <span class="user-shortened__name">{{ user.name }}<template v-if="user.birthday !== null">, {{ getUserAge(user.birthday) }}</template></span>
      <slot name="id" />
    </router-link>
    <div v-else>
      <div
        v-background="{img: user.avatar.img_medium, imgClass: 'obj-fit-bg'}"
        class="user-shortened"
      >
        <img
          :src="user.avatar.img_medium"
          class="obj-fit-bg"
          :alt="user.name + $t('shared.alts.ava')"
        >
        <span
          v-if="user.online"
          class="online"
        />
        <span
          v-if="userWebcam(user.camera).status"
          class="webcam rbi-webcam"
          :class="{hd: userWebcam(user.camera).hd}"
        />
        <span
          v-if="user.new"
          class="is-new"
        />
      </div>
      <span class="user-shortened__name">{{ user.name }}<template v-if="user.birthday !== null">, {{ getUserAge(user.birthday) }}</template></span>
      <slot name="id" />
    </div>
  </div>
</template>

<script>
import { AGE, WEBCAM } from '../../../mixins/utils';
import { PREVIEW_MODAL } from '../../../mixins/formsActions';

export default {
  components: {},
  mixins: [AGE, WEBCAM, PREVIEW_MODAL],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
};
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/vars";

  a {
    text-decoration: none;
  }

  .is-new {
    position: absolute;
    top: 0;
    left: 0;
    background: $green;
    color: $white;
    padding: 5px 7px 5px 5px;
    display: flex;
    -webkit-border-radius: 0 0 5px 0;
    -moz-border-radius: 0 0 5px 0;
    border-radius: 0 0 10px 0;
    align-content: center;
    text-transform: uppercase;
    z-index: 5;

    &:before {
      content: 'New';
      font-size: 9px;
      line-height: 1;
    }
  }

  .webcam {
    position: absolute;
    top: 5px;
    right: 5px;
    background: $white;
    display: inline-block;
    width: 30px;
    height: 30px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    text-align: center;
    line-height: 30px;
    color: $black;
    font-weight: 600;
    z-index: 5;

    &.hd {
      &:after {
        content: 'hd';
        text-transform: uppercase;
        display: flex;
        background: $red;
        color: $white;
        font-size: 8px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        align-items: center;
        font-weight: 400;
        justify-content: center;
        width: 20px;
        height: 20px;
        position: absolute;
        top: -5px;
        right: -5px;
        border: 2px solid #ffffff;
      }

    }
  }

  .online {
    position: absolute;
    left: 0;
    bottom: 10px;
    background: $white;
    display: inline-block;
    padding: 3px;
    -webkit-border-radius: 0 10px 10px 0;
    -moz-border-radius: 0 10px 10px 0;
    border-radius: 0 10px 10px 0;
    z-index: 5;

    &:before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      background: $green;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
    }
  }

  .link a {
    color: #3e3f42;
  }
</style>
