<template>
  <div
    class="news-box--birthday"
    :class="{open: open}"
  >
    <div class="news-box--birthday__inner">
      <div class="news-box--birthday__caption">
        <h3 class="news-box--birthday__heading decor">
          {{ $t('actionPages.news.bdCaption') }} <span class="text-muted">({{ users.length }})</span>
        </h3>
      </div>
      <div class="news-box--birthday__grid">
        <user-shortened
          v-for="user in formattedUsers"
          :key="user.user_id"
          :user="user"
        >
          <div
            slot="id"
            class="uid"
          >
            ID: {{ user.user_id }}
          </div>
        </user-shortened>
      </div>
      <div
        v-if="this.users.length > limit"
        class="load-wrap d-flexset-flex-x-center set-flex-y-center"
        @click="open = !open"
      >
        <button
          class="news-box--item__data--more"
          :class="{active: open}"
        >
          <span>
            <span class="underline">{{ expandText }}</span>
          </span>
          <span class="rbi rbi-down-arrow" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import UserShortened from '../../Shared/parts/UserShortened.vue';

export default {
  components: {
    UserShortened,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    width: Number,
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    /**
       * Получить список пользователей для отображения
       *
       * @return {array}
       */
    formattedUsers() {
      if (this.users.length > this.limit) {
        return this.open ? this.users : this.users.slice(0, this.limit);
      }
      return this.users;
    },

    /**
       * Установить лимит отображения в зависимости от ширины вьюпорта
       *
       * @return {number|undefined}
       */
    limit() {
      let tmp;
      // 16, 5, 3 - values also used in CSS grid. Depends on current display width
      if (this.width >= 992) {
        tmp = 8;
      } else if (this.width < 992 && this.width > 768) {
        tmp = 6;
      } else if (this.width <= 768 && this.width > 480) {
        tmp = 3;
      } else {
        tmp = 3;
      }
      return tmp;
    },

    /**
       * Текст для кнопки "показать еще"
       *
       * @return {string}
       */
    expandText() {
      return this.open ? this.$t('actionPages.news.hide') : this.$t('actionPages.news.viewAll');
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/vars";

</style>
