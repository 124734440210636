<template>
  <router-link
    tag="div"
    :to="{name: 'profile', params: { id: user.user_id } }"
    class="news-box--user-responsive d-lg-none d-md-flex"
  >
    <div class="news-box--user-responsive__ava">
      <img
        :src="setPhotoSource(user.avatar.img_small)"
        :alt="user.name + $t('shared.alts.ava')"
      >
    </div>
    <div>
      <div class="name">
        <p>
          {{ user.name }}<template v-if="user.birthday !== null">
            , {{ getUserAge( user.birthday ) }}
          </template>
        </p>
      </div>
      <div class="uid">
        ID: {{ user.user_id }}
      </div>
    </div>
  </router-link>
</template>

<script>
import { PHOTO_SRC, AGE } from '../../../mixins/utils';

export default {
  mixins: [PHOTO_SRC, AGE],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>
