/**
 * Created by lara on 27.03.2017.
 */
import moment from 'moment';

export const COMPUTED_PROFILE = {
  computed: {
    /**
		 * Получить строчное представление даты
		 *
		 * @return {string}
		 */
    date() {
      return ` ${moment(this.user.birthday * 1000).format('LL')} (${this.getUserAge(this.user.birthday)} ${this.$t('profile.years')})`;
    },
    /**
		 * Получить строчное представление языков из массива
		 *
		 * @return {string}
		 */
    langs() {
      const tmp = [
        {
          k: this.$t('profile.eng'),
          v: this.user.profile_additional_info.lang_english,
        },
        {
          k: this.$t('profile.sp'),
          v: this.user.profile_additional_info.lang_spanish,
        },
        {
          k: this.$t('profile.fr'),
          v: this.user.profile_additional_info.lang_french,
        },
        {
          k: this.$t('profile.gr'),
          v: this.user.profile_additional_info.lang_german,
        },
        {
          k: this.$t('profile.pt'),
          v: this.user.profile_additional_info.lang_portuguese,
        },
      ];

      return tmp.filter((item) => (item.v == 'no' ? false : item)).map((item) => `${item.k} (${item.v})`).join(', ');
    },
  },
};

export const MEASURE_CALCULATIONS = {
  data() {
    return {
      constants: {
        kgLbs: 2.20462262185,
        lbsKg: 0.453592,
        feetCm: 30.48,
        inchCm: 2.54,
      },
    };
  },
  methods: {
    /**
		 * Конвертация КГ в Фунты
		 *
		 * @param v {number}
		 * @return {number}
		 */
    kgToLbs(v) {
      return v * this.constants.kgLbs;
    },
    /**
		 * Конвертация Фунты в КГ
		 *
		 * @param v {number}
		 * @return {number}
		 */
    lbsToKg(v) {
      return v * this.constants.lbsKg;
    },
    /**
		 * Возвращает строчное представление из футов и дюймов
		 *
		 * @param str {string}
		 * @return {string}
		 */
    formatStringFeetInch(str) {
      const tmp = str.split('.');
      return `${tmp[0]}' ${tmp[1]}"`;
    },
    /**
		 * Воззвращает строчное представление из футов и дюймов
		 *
		 * @param feet {number}
		 * @param inches {number}
		 * @return {string}
		 */
    formatFeetInch(feet, inches) {
      return `${feet}' ${inches}"`;
    },
    /**
		 * конвертация сантиметров в дюймы и футы
		 *
		 * @param v {number}
		 * @return {{feet: number, inches: string}}
		 */
    cmToFeetInch(v) {
      let inches = (v * 0.393700787).toFixed(0);
      const feet = Math.floor(inches / 12);
      inches %= 12;
      return {
        feet,
        inches,
      };
    },
    /**
		 * конвертация фунтов и дюймов в сантиметры
		 *
		 * @param feet {number}
		 * @param inch {number}
		 * @return {number}
		 */
    feetInchToCm(feet, inch) {
      if (feet !== 'no' && inch !== 'no') {
        return Math.floor(feet * this.constants.feetCm + inch * this.constants.inchCm);
      }
      return 0;
    },
  },
};
