<template>
  <div class="main-block search-page">
    <div class="white-box action-page">
      <div class="responsive-box-search d-block d-md-block d-lg-none" :class="{ open: collapsed }">
        <div class="responsive-search">
          <div class="responsive-search--heading__wrap">
            <p class="responsive-search--heading">
              <span v-if="sex.isMan">{{ $t('actionPages.search.sWomen') }}</span>
              <span v-if="sex.isWoman">{{ $t('actionPages.search.sMen') }}</span>
            </p>
          </div>
          <form @submit.prevent="startSearch({ randomize: true })">
            <div v-if="sex.isMan" class="responsive-search__tools">
              <div v-show="!collapsed" class="responsive-search__short-search item">
                <div class="form-group">
                  <input
                    v-model="searchParams.nameOrId"
                    type="text"
                    class="form-control"
                    :placeholder="$t('shared.placeholders.nameId')"
                  />
                </div>
              </div>
              <div class="responsive-search__checkboxes item" :class="{ collapsed }">
                <div class="form-group item">
                  <custom-checkbox id="isOnline" v-model="searchParams.is_online">
                    {{ $t('actionPages.search.online') }}
                  </custom-checkbox>
                </div>
                <div v-if="sex.isMan" class="form-group item">
                  <custom-checkbox id="withCam" v-model="searchParams.with_web_cam">
                    {{ $t('actionPages.search.cam') }}
                  </custom-checkbox>
                </div>
                <div v-if="sex.isWoman" class="form-group item">
                  <custom-checkbox id="new-1" v-model="searchParams.isWoman">
                    {{ $t('actionPages.search.new') }}
                  </custom-checkbox>
                </div>
                <template v-if="collapsed">
                  <div class="form-group item">
                    <custom-checkbox id="newWomenDesk" v-model="searchParams.new_woman">
                      {{ $t('actionPages.search.new') }}
                    </custom-checkbox>
                  </div>

                  <div class="form-group item">
                    <custom-checkbox id="camHDdesk" v-model="searchParams.with_hd_cam">
                      {{ $t('actionPages.search.camHD') }}
                    </custom-checkbox>
                  </div>
                </template>
              </div>
            </div>
            <div v-if="sex.isWoman" class="responsive-search__tools">
              <div v-show="!collapsed" class="responsive-search__short-search item">
                <div class="form-group">
                  <input
                    v-model="searchParams.nameOrId"
                    type="text"
                    class="form-control"
                    :placeholder="$t('shared.placeholders.nameId')"
                  />
                </div>
              </div>
              <div class="responsive-search__checkboxes form-group">
                <div class="form-group item">
                  <custom-checkbox id="o-r-s" v-model="searchParams.is_online">
                    {{ $t('actionPages.search.online') }}
                  </custom-checkbox>
                </div>
                <div class="form-group item">
                  <custom-checkbox id="new-1" v-model="searchParams.new_woman">
                    {{ $t('actionPages.search.new') }}
                  </custom-checkbox>
                </div>
              </div>
            </div>
            <div v-if="sex.isWoman" v-show="collapsed" class="responsive-search__toggling-search">
              <div class="sliders-custom item">
                <div class="slider-values">
                  <span>{{
                    $t('actionPages.search.age', [
                      searchParams.ageValue[0],
                      searchParams.ageValue[1],
                    ])
                  }}</span>
                  <vue-slider
                    ref="sl-a-2"
                    v-model="searchParams.ageValue"
                    :tooltip="false"
                    :min="searchParams.minAge"
                    :max="searchParams.maxAge"
                  />
                  <input v-model="searchParams.ageValue" type="hidden" />
                </div>
              </div>
              <div class="item form-group">
                <input
                  v-model="searchParams.nameOrId"
                  type="text"
                  class="form-control"
                  :placeholder="$t('shared.placeholders.nameId')"
                />
              </div>
              <div class="item form-group single-select">
                <multiselect
                  v-model="searchParams.countryVal"
                  :options="formattedCountries"
                  :show-labels="false"
                  :placeholder="$t('profile.country')"
                />
              </div>
              <div class="item form-group single-select">
                <multiselect
                  v-model="searchParams.maritalVal"
                  :hide-selected="true"
                  :options="searchParams.marital"
                  :searchable="false"
                  track-by="k"
                  label="v"
                  :show-labels="false"
                />
              </div>
              <div class="item form-group single-select">
                <multiselect
                  v-model="selectedEngl"
                  :hide-selected="true"
                  :options="searchParams.languageSkill"
                  :searchable="false"
                  track-by="k"
                  label="v"
                  :show-labels="false"
                />
              </div>
            </div>
            <div v-if="sex.isMan" v-show="collapsed" class="responsive-search__toggling-search">
              <div class="sliders-custom item">
                <div class="slider-values">
                  <span>{{
                    $t('actionPages.search.age', [
                      searchParams.ageValue[0],
                      searchParams.ageValue[1],
                    ])
                  }}</span>
                  <vue-slider
                    ref="sl-a-2"
                    v-model="searchParams.ageValue"
                    :tooltip="false"
                    :min="searchParams.minAge"
                    :max="searchParams.maxAge"
                  />
                  <input v-model="searchParams.ageValue" type="hidden" />
                </div>
              </div>
              <div class="form-group item">
                <input
                  v-model="searchParams.nameOrId"
                  type="text"
                  class="form-control"
                  :placeholder="$t('shared.placeholders.nameId')"
                />
              </div>
              <div class="item form-group single-select">
                <multiselect
                  v-model="searchParams.countryVal"
                  :options="formattedCountries"
                  :show-labels="false"
                  :placeholder="$t('profile.country')"
                />
              </div>
              <div class="item form-group single-select">
                <multiselect
                  v-model="searchParams.bodyVal"
                  :hide-selected="true"
                  :options="searchParams.body"
                  :searchable="false"
                  track-by="k"
                  label="v"
                  :show-labels="false"
                />
              </div>
              <div class="slider-values form-group item">
                {{
                  $t('actionPages.search.height', [
                    formatStringFeetInch(searchParams.rangeHeight[0]),
                    formatStringFeetInch(searchParams.rangeHeight[1]),
                  ])
                }}
                <vue-slider
                  ref="sl-h-2"
                  v-model="searchParams.rangeHeight"
                  :tooltip="false"
                  :data="data"
                />
                <input v-model="searchParams.rangeHeight" type="hidden" />
              </div>
              <div class="item form-group single-select">
                <multiselect
                  v-model="searchParams.hairVal"
                  :hide-selected="true"
                  :options="searchParams.hairColor"
                  :searchable="false"
                  track-by="k"
                  label="v"
                  :show-labels="false"
                />
              </div>
              <div class="item form-group single-select">
                <multiselect
                  v-model="searchParams.eyesVal"
                  :hide-selected="true"
                  :options="searchParams.eyesColor"
                  :searchable="false"
                  track-by="k"
                  label="v"
                  :show-labels="false"
                />
              </div>
              <div class="item form-group single-select">
                <multiselect
                  v-model="selectedEngl"
                  :hide-selected="true"
                  :options="searchParams.languageSkill"
                  :searchable="false"
                  track-by="k"
                  label="v"
                  :show-labels="false"
                />
              </div>
            </div>
            <div class="btn-group">
              <rb-button type="submit" class="btn-black">
                {{ $t('actionPages.search.submit') }}
              </rb-button>

              <button
                class="btn show-more"
                :class="{ open: collapsed }"
                type="button"
                @click="toggleResponsive"
              >
                <span class="rbi rbi-down-arrow" />
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="desktop-search d-none d-md-none d-lg-block">
        <form
          :class="{ open: collapsedDesktop }"
          @submit.prevent="startSearch({ randomize: true })"
        >
          <div class="desktop-search__main-row">
            <div class="col-3">
              <div class="item">
                <div class="form-group">
                  <input
                    v-model="searchParams.nameOrId"
                    type="text"
                    class="form-control"
                    :placeholder="$t('shared.placeholders.nameId')"
                  />
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="sliders-custom item">
                <div class="slider-values">
                  <span>{{
                    $t('actionPages.search.age', [
                      searchParams.ageValue[0],
                      searchParams.ageValue[1],
                    ])
                  }}</span>
                  <vue-slider
                    ref="sl-a-1"
                    v-model="searchParams.ageValue"
                    :tooltip="false"
                    :min="searchParams.minAge"
                    :max="searchParams.maxAge"
                  />
                  <input v-model="searchParams.ageValue" type="hidden" />
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="d-flex justify-content-between align-items-center">
                <div class="form-group item">
                  <custom-checkbox id="isOnline" v-model="searchParams.is_online">
                    {{ $t('actionPages.search.online') }}
                  </custom-checkbox>
                </div>
                <div v-if="sex.isMan" class="form-group item">
                  <custom-checkbox id="withCam" v-model="searchParams.with_web_cam">
                    {{ $t('actionPages.search.cam') }}
                  </custom-checkbox>
                </div>
                <div v-if="sex.isWoman" class="form-group item">
                  <custom-checkbox id="new-1" v-model="searchParams.new_woman">
                    {{ $t('actionPages.search.new') }}
                  </custom-checkbox>
                </div>
                <rb-button type="submit" class="btn-black">
                  {{ $t('actionPages.search.submit') }}
                </rb-button>
              </div>
            </div>
          </div>
          <div v-if="sex.isMan" class="desktop-search__collapsible">
            <div class="collapse-row d-flex">
              <div class="col-3">
                <div class="form-group item">
                  <custom-checkbox id="newWomenDesk" v-model="searchParams.new_woman">
                    {{ $t('actionPages.search.new') }}
                  </custom-checkbox>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group item">
                  <custom-checkbox id="camHDdesk" v-model="searchParams.with_hd_cam">
                    {{ $t('actionPages.search.camHD') }}
                  </custom-checkbox>
                </div>
              </div>
              <div class="col-3">
                <div class="item-flex item single-select">
                  <multiselect
                    v-model="searchParams.countryVal"
                    :options="formattedCountries"
                    :show-labels="false"
                    :placeholder="$t('profile.country')"
                  />
                </div>
              </div>
              <div class="col-3">
                <div class="item-flex item single-select">
                  <multiselect
                    v-model="searchParams.hairVal"
                    :hide-selected="true"
                    :options="searchParams.hairColor"
                    :searchable="false"
                    track-by="k"
                    label="v"
                    :show-labels="false"
                  />
                </div>
              </div>
            </div>
            <div class="collapse-row d-flex">
              <div class="col-3">
                <div class="sliders-custom item-flex item">
                  <div class="slider-values">
                    <span>
                      {{
                        $t('actionPages.search.height', [
                          formatStringFeetInch(searchParams.rangeHeight[0]),
                          formatStringFeetInch(searchParams.rangeHeight[1]),
                        ])
                      }}</span
                    >
                    <vue-slider
                      ref="sl-h-1"
                      v-model="searchParams.rangeHeight"
                      :tooltip="false"
                      :data="data"
                    />
                    <input v-model="searchParams.rangeHeight" type="hidden" />
                  </div>
                </div>
              </div>
              <div class="col-3">
                <div class="item-flex item single-select">
                  <multiselect
                    v-model="selectedEngl"
                    :hide-selected="true"
                    :options="searchParams.languageSkill"
                    :searchable="false"
                    track-by="k"
                    label="v"
                    :show-labels="false"
                  />
                </div>
              </div>
              <div class="col-3">
                <div class="item-flex item single-select">
                  <multiselect
                    v-model="searchParams.bodyVal"
                    :hide-selected="true"
                    :options="searchParams.body"
                    :searchable="false"
                    track-by="k"
                    label="v"
                    :show-labels="false"
                  />
                </div>
              </div>
              <div class="col-3">
                <div class="item-flex item single-select">
                  <multiselect
                    v-model="searchParams.eyesVal"
                    :hide-selected="true"
                    :options="searchParams.eyesColor"
                    :searchable="false"
                    track-by="k"
                    label="v"
                    :show-labels="false"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="sex.isWoman" class="desktop-search__collapsible women-desktop">
            <div class="col-4">
              <multiselect
                v-model="selectedEngl"
                :hide-selected="true"
                :options="searchParams.languageSkill"
                :searchable="false"
                track-by="k"
                label="v"
                :show-labels="false"
              />
            </div>
            <div class="col-4">
              <multiselect
                v-model="searchParams.countryVal"
                :options="formattedCountries"
                :show-labels="false"
                :placeholder="$t('profile.country')"
              />
            </div>
            <div class="col-4">
              <multiselect
                v-model="searchParams.maritalVal"
                :hide-selected="true"
                :options="searchParams.marital"
                :searchable="false"
                track-by="k"
                label="v"
                :show-labels="false"
              />
            </div>
          </div>
        </form>
        <div class="button-wrap">
          <button
            class="show-more"
            :class="{ open: collapsedDesktop }"
            type="button"
            @click="toggleDesktop"
          >
            <span>{{ $t('actionPages.search.advanced') }}</span>
            <span class="rbi rbi-down-arrow" />
          </button>
        </div>
      </div>
      <div class="action-page--grid search-grid">
        <div class="action-page--heading">
          <h1 class="action-page--heading__h decor">
            {{ $t('actionPages.search.result', [count]) }}
          </h1>
        </div>
        <p v-show="!searchResults.length && !showPreloader" class="alert alert-warning">
          {{ $t('actionPages.search.noMatch') }}
        </p>
        <div class="search-grid__inner users-block-flex">
          <app-user-preview
            v-for="user in searchResults"
            :key="user.user_id"
            :user="user"
            :disabled-props="user.disabled"
            :column="true"
          />
        </div>
        <div v-show="showPreloader" class="loader--wrap">
          <div class="loader" />
        </div>
        <div class="button-wrap">
          <lazy-load
            v-show="searchResults.length && !noData"
            :counter="defaultLimit"
            @click.native="loadMore"
          />
        </div>
        <div v-show="noData && !showPreloader && searchResults.length">
          <hr />
          <p class="text-center text-muted">
            {{ $t('actionPages.search.noLoad') }}
          </p>
        </div>
      </div>
    </div>
    <preview-modal />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import vueSlider from 'vue-slider-component/src/vue2-slider.vue';
import PreviewModal from '../Shared/modals/PreviewModal.vue';
import UserPreview from '../Shared/UserPreview.vue';
import LazyLoad from './parts/LoadMore.vue';
import { PHOTO_SRC } from '../../mixins/utils';
import { MEASURE_CALCULATIONS } from '../../mixins/types/profile';
import { PREPARE_DATA } from '../../mixins/userActions';
import customCheckbox from '../Shared/parts/CustomCheckbox';
import RbButton from '../Button';

export default {
  data() {
    return {
      data: [
        '4.0',
        '4.1',
        '4.2',
        '4.3',
        '4.4',
        '4.5',
        '4.6',
        '4.7',
        '4.8',
        '4.9',
        '4.10',
        '4.11',
        '5.0',
        '5.1',
        '5.2',
        '5.3',
        '5.4',
        '5.5',
        '5.6',
        '5.7',
        '5.8',
        '5.9',
        '5.10',
        '5.11',
        '6.0',
        '6.1',
        '6.2',
        '6.3',
        '6.4',
        '6.5',
        '6.6',
        '6.7',
        '6.8',
        '6.9',
        '6.10',
        '6.11',
      ],
      showPreloader: true,
      defaultLimit: 20,
      limit: 20,
      noData: false,
      collapsedDesktop: false,
      collapsed: false,
      selectedEngl: { k: '', v: this.$t('profile.lvlEng') },
      count: 0,
      searchParams: {
        languageSkill: [
          { k: '', v: this.$t('profile.lvlEng') },
          { k: 'basic', v: this.$t('profile.vLvlLangs.basic') },
          { k: 'intermediate', v: this.$t('profile.vLvlLangs.intermediate') },
          { k: 'good', v: this.$t('profile.vLvlLangs.good') },
          { k: 'fluent', v: this.$t('profile.vLvlLangs.fluent') },
        ],
        hairColor: [
          { k: '', v: this.$t('profile.hairColor') },
          { k: 'blonde', v: this.$t('profile.vHair.blond') },
          { k: 'brown', v: this.$t('profile.vHair.brown') },
          { k: 'redhead', v: this.$t('profile.vHair.redhead') },
          { k: 'brunette', v: this.$t('profile.vHair.brunette') },
          { k: 'colored', v: this.$t('profile.vHair.colored') },
        ],
        body: [
          { k: '', v: this.$t('profile.bodyType') },
          { k: 'thin', v: this.$t('profile.vBody.thin') },
          { k: 'slender', v: this.$t('profile.vBody.slender') },
          { k: 'athletic', v: this.$t('profile.vBody.athletic') },
          { k: 'a few extra pounds', v: this.$t('profile.vBody.extra') },
          { k: 'plump', v: this.$t('profile.vBody.plump') },
        ],
        marital: [
          { k: '', v: this.$t('profile.marital') },
          { k: 'single', v: this.$t('profile.vMarital.single') },
          { k: 'never been married', v: this.$t('profile.vMarital.never') },
          { k: 'divorced', v: this.$t('profile.vMarital.divorced') },
          { k: 'widow', v: this.$t('profile.vMarital.widow') },
        ],
        eyesColor: [
          { k: '', v: this.$t('profile.eyesColor') },
          { k: 'black', v: this.$t('profile.vEyes.black') },
          { k: 'green', v: this.$t('profile.vEyes.green') },
          { k: 'gray', v: this.$t('profile.vEyes.gray') },
          { k: 'brown', v: this.$t('profile.vEyes.brown') },
          { k: 'light-brown', v: this.$t('profile.vEyes.lightBrown') },
          { k: 'blue', v: this.$t('profile.vEyes.blue') },
        ],
        is_online: false,
        new_woman: false,
        with_web_cam: false,
        with_hd_cam: false,
        ageValue: [18, 80],
        minAge: 18,
        maxAge: 80,
        rangeHeight: ['4.0', '6.11'],
        countryVal: '', // this.$t('profile.country'),
        maritalVal: { k: '', v: this.$t('profile.marital') },
        hairVal: { k: '', v: this.$t('profile.hairColor') },
        bodyVal: { k: '', v: this.$t('profile.bodyType') },
        eyesVal: { k: '', v: this.$t('profile.eyesColor') },
        countries: [],
        nameOrId: '',
      },
      searchResults: [],
      sort: {
        name: '',
        date: 'desc',
      },
    };
  },
  computed: {
    /**
     *  Отформатировать список стран
     */
    formattedCountries() {
      if (this.searchParams.countries.length) {
        // return [ this.$t('profile.country') ].concat( this.searchParams.countries.map( item => item.name ) );
        return this.searchParams.countries.map((item) => item.name);
      }
      return [];
    },
    user() {
      return this.$store.getters.user;
    },
    sex() {
      return this.$store.getters.sex;
    },
    /**
     *  Авторизирован ли пользователь на сайте
     */
    isAuthorized() {
      return !!window.localStorage['access-token'];
    },
    sEngl() {
      return this.selectedEngl;
    },
    sOnline() {
      return this.searchParams.is_online;
    },
    sNewUser() {
      return this.searchParams.new_woman;
    },
    sWebCam() {
      return this.searchParams.with_web_cam;
    },
    sWebCamHD() {
      return this.searchParams.with_hd_cam;
    },
    sMinAge() {
      return this.searchParams.ageValue[0];
    },
    sMaxAge() {
      return this.searchParams.ageValue[1];
    },
    sMinHeight() {
      return this.searchParams.rangeHeight[0];
    },
    sMaxHeight() {
      return this.searchParams.rangeHeight[1];
    },
    sCountry() {
      return this.searchParams.countryVal;
    },
    sHair() {
      return this.searchParams.hairVal;
    },
    sBody() {
      return this.searchParams.bodyVal;
    },
    sEyes() {
      return this.searchParams.eyesVal;
    },
    sMarital() {
      return this.searchParams.maritalVal;
    },
    sNameID() {
      return this.searchParams.nameOrId;
    },
  },
  watch: {
    /**
     * Проверка привилегий для взаимодейсвтвия с пользователями
     */
    searchResults(v, old) {
      this.extendedCheck(v, old);
    },
    /**
     *  Запустить поиск при переключении значения переменной знание англ
     */
    sEngl() {
      this.debSearch({ offset: 0, randomize: true });
    },
    /**
     * Запустить поиск при переключении значения переменной онлайн
     */
    sOnline() {
      this.debSearch({ offset: 0, randomize: true });
    },
    /**
     * Запустить поиск при переключении значения переменной is_new
     */
    sNewUser() {
      this.debSearch({ offset: 0, randomize: true });
    },
    /**
     * Запустить поиск при переключении значения переменной вебкамера
     */
    sWebCam() {
      this.debSearch({ offset: 0, randomize: true });
    },
    /**
     * Запустить поиск при переключении значения переменной вебка HD
     */
    sWebCamHD() {
      this.debSearch({ offset: 0, randomize: true });
    },
    /**
     * Запустить поиск при переключении значения переменной минимальный возраст
     */
    sMinAge() {
      this.debSearch({ offset: 0, randomize: true });
    },
    /**
     * Запустить поиск при переключении значения переменной максимальный возраст
     */
    sMaxAge() {
      this.debSearch({ offset: 0, randomize: true });
    },
    /**
     * Запустить поиск при переключении значения переменной мин. рост
     */
    sMinHeight() {
      this.debSearch({ offset: 0, randomize: true });
    },
    /**
     * Запустить поиск при переключении значения переменной макс. рост
     */
    sMaxHeight() {
      this.debSearch({ offset: 0, randomize: true });
    },
    /**
     * Запустить поиск при переключении значения переменной страна
     */
    sCountry() {
      this.debSearch({ offset: 0, randomize: true });
    },
    /**
     * Запустить поиск при переключении значения переменной цвет волос
     */
    sHair() {
      this.debSearch({ offset: 0, randomize: true });
    },
    /**
     * Запустить поиск при переключении значения переменной телосложение
     */
    sBody() {
      this.debSearch({ offset: 0, randomize: true });
    },
    /**
     * Запустить поиск при переключении значения переменной цвет глаз
     */
    sEyes() {
      this.debSearch({ offset: 0, randomize: true });
    },
    /**
     * Запустить поиск при переключении значения переменной семейный статус
     */
    sMarital() {
      this.debSearch({ offset: 0, randomize: true });
    },
    /**
     * Запустить поиск при переключении значения переменной имя или ID
     */
    sNameID() {
      this.debSearch({ offset: 0, randomize: true });
    },
  },
  methods: {
    /**
     * Загрузить больше результатов
     */
    loadMore() {
      this.startSearch({
        offset: this.searchResults.length,
      });
    },

    /**
     *  Установить мин. рост
     */
    setMinHeight() {
      const tmp = `${this.searchParams.rangeHeight[0]}`.split('.');
      const feet = +tmp[0];
      const inches = +tmp[1] || 0;

      return this.feetInchToCm(feet, inches);
    },

    /**
     * Установить макс. рост
     */
    setMaxHeight() {
      const tmp = `${this.searchParams.rangeHeight[1]}`.split('.');
      const feet = +tmp[0];
      const inches = +tmp[1] || 0;

      return this.feetInchToCm(feet, inches);
    },

    /**
     * Очистить значение переменной "телосложение"
     *
     * @param v {string}
     */
    clearBody(v) {
      v === 'Body' ? (this.searchParams.bodyVal = '') : (this.searchParams.bodyVal = v);
    },

    /**
     * Очистить значение переменной "цвет волос"
     *
     * @param v {string}
     */
    clearHair(v) {
      v === 'Hair color' ? (this.searchParams.hairVal = '') : (this.searchParams.hairVal = v);
    },

    /**
     * Очистить значение переменной "цвет глаз"
     *
     * @param v {string}
     */
    clearEyes(v) {
      v === 'Eyes color' ? (this.searchParams.eyesVal = '') : (this.searchParams.eyesVal = v);
    },
    /* clearCountry( v ) {
                v === this.$t('profile.country') ? this.searchParams.countryVal = '' : this.searchParams.countryVal = v;
            }, */
    clearLang(v) {
      v === 'English level' ? (this.selectedEngl = '') : (this.selectedEngl = v);
    },

    /**
     * Переключить отображение спрятанных элементов на декстпое
     */
    toggleDesktop() {
      this.collapsedDesktop = !this.collapsedDesktop;
      this.refresh();
    },

    /**
     * Переключить отображение спрятанных элементов на респониве
     */
    toggleResponsive() {
      this.collapsed = !this.collapsed;
      this.refresh();
    },

    /**
     * Обновить состояние range-компонентов
     */
    refresh() {
      const self = this;
      setTimeout(() => {
        self.$refs['sl-a-1'].refresh();
        self.$refs['sl-a-2'].refresh();
        if (!this.sex.isWoman) {
          self.$refs['sl-h-1'].refresh();
          self.$refs['sl-h-2'].refresh();
        }
      }, 50);
    },

    /**
     * Сделать тип сортировки случайным
     */
    randomize() {
      if (!this.sex.isWoman && window.localStorage.sex !== 'woman') {
        if (Math.random() > 0.5) {
          this.sort.name = Math.random() > 0.5 ? 'asc' : 'desc';
          this.sort.date = '';
        } else {
          this.sort.date = Math.random() > 0.5 ? 'asc' : 'desc';
          this.sort.name = '';
        }
      }
    },

    /**
     * Загрузка данных с задержкой в 300мс
     *
     * @param offset {number} с какого элемента  делать выборку
     * @param randomize {boolean} нужно ли запускать рандомайзер
     */
    debSearch: debounce(function ({ offset, randomize }) {
      this.startSearch({ offset, randomize });
    }, 300),

    /**
     * Загрузить данные
     *
     * @param offset {number} с какого элемента  делать выборку
     * @param randomize {boolean} нужно ли запускать рандомайзер
     */
    startSearch({ offset, randomize }) {
      this.clearUpdate();
      this.showPreloader = true;
      this.noData = false;

      if (randomize) this.randomize();

      this.$http
        .post('v1/profile/search', {
          access_token: window.localStorage['access-token'],
          limit: this.defaultLimit,
          offset: offset || 0,
          is_online: this.searchParams.is_online ? 1 : 0 || 0,
          new: this.searchParams.new_woman ? 1 : 0,
          with_web_cam: this.searchParams.with_web_cam || 0,
          with_hd_cam: this.searchParams.with_hd_cam || 0,
          age_min:
            this.searchParams.ageValue[0] === this.searchParams.minAge
              ? ''
              : this.searchParams.ageValue[0],
          age_max:
            this.searchParams.ageValue[1] === this.searchParams.maxAge
              ? ''
              : this.searchParams.ageValue[1],
          min_height: this.sex.isMan ? this.setMinHeight() : '',
          max_height: this.sex.isMan ? this.setMaxHeight() : '',
          family_status: this.searchParams.maritalVal ? this.searchParams.maritalVal.k : '',
          body: this.searchParams.bodyVal ? this.searchParams.bodyVal.k : '' || '',
          eyes_color: this.searchParams.eyesVal ? this.searchParams.eyesVal.k : '' || '',
          hair_color: this.searchParams.hairVal ? this.searchParams.hairVal.k : '' || '',
          name_or_id: this.searchParams.nameOrId,
          language_english: this.selectedEngl ? this.selectedEngl.k : '',
          country: this.searchParams.countryVal || '',
          sort_by_name: this.sort.name,
          sort_by_date: this.sort.date,
        })
        .then(
          (response) => {
            response.json().then(
              (response) => {
                if (response.status) {
                  this.count = response.count;
                  if (response.result.length < this.defaultLimit) this.noData = true;
                  if (offset) {
                    this.searchResults = this.searchResults.concat(response.result);
                  } else {
                    this.searchResults = response.result;
                  }
                }
                this.showPreloader = false;
              },
              (error) => {
                console.error(error);
              }
            );
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
  components: {
    appUserPreview: UserPreview,
    PreviewModal,
    vueSlider,
    LazyLoad,
    customCheckbox,
    RbButton,
  },
  mixins: [PHOTO_SRC, MEASURE_CALCULATIONS, PREPARE_DATA],
  mounted() {
    document.title = this.$t('actionPages.search.title');
    this.startSearch({ randomize: true });
    setTimeout(() => {
      this.collapsed = false;
    }, 0);

    /**
     * Получить список стран
     */

    this.$http.post('v1/user-guest/country', {}).then(
      (response) => {
        response.json().then(
          (response) => {
            response.status ? (this.searchParams.countries = response.result) : '';
          },
          (error) => {
            console.error(error);
          }
        );
      },
      (error) => {
        console.log(error);
      }
    );
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/vars';

.responsive-search__tools .show-more {
  padding: 0;
  border-radius: 50%;
}

.rb-select {
  width: 100%;
}

.button-wrap {
  padding: 25px 0 30px;
  border: none;

  .show-more {
    background: none;
    border: none;
    cursor: pointer;
    color: $black;
    font-size: 14px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 1.4px;
    display: flex;
    align-items: center;
    outline: none;

    > span {
      transition: 0.3s;

      &:first-child {
        border-bottom: 1px dotted $black;
      }
    }

    &:hover {
      > span {
        &:first-child {
          color: $red;
          border-color: $red;
        }
      }

      .rbi {
        color: $red;
      }
    }

    &.open {
      .rbi {
        transform: rotate(180deg);
      }
    }

    .rbi {
      font-size: 18px;
      margin-left: 10px;
      transition: 0.3s;
      transform-origin: 50% 50%;
    }
  }
}

.search-page {
  .action-page {
    padding-top: 25px;
  }
}

.sliders-custom {
  height: 100%;
  display: flex;
  align-items: flex-end !important;
  width: 100%;

  .slider-values {
    width: 100%;

    > span {
      padding: 0.8125rem 0;
    }

    .vue-slider-component {
      padding: 0.8125rem 10px 0 !important;
    }
  }
}

.responsive-search {
  position: relative;
  padding: 30px 25px;
  background: $light-grey;
  margin: 0 25px 20px;

  &--heading {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: 0.9px;
    text-align: center;
  }

  &__tools {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .responsive-search__short-search {
      width: 55%;
      .form-control {
        width: 100%;
        background: none;
      }
    }
    .responsive-search__checkboxes {
      width: 41%;
      display: flex;
      justify-content: space-between;
      align-content: center;
      height: 100%;
      &.collapsed {
        width: 100%;
      }
    }
  }

  &__toggling-search {
    margin-top: 25px;
    .form-control {
      width: 100%;
      background-color: transparent;
    }
    .sliders-custom {
      width: 100%;
    }
    .item {
      margin-bottom: 15px;
    }
  }
  .btn-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    .btn {
      display: flex;
      height: 45px;
      align-items: center;
      justify-content: center;
      padding: 10px 30px;
      &.show-more {
        width: 45px;
        padding: 0;
        margin-left: 25px;
        .rbi {
          transform-origin: 50% 50%;
          transition: 0.3s;
        }
        &.open {
          .rbi {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.desktop-search {
  position: relative;
  padding: 25px 10px;
  background: $light-grey;
  margin: 0 25px 20px;

  .form-control {
    background-color: transparent;
    width: 100%;
  }

  form {
    display: flex;
    flex-wrap: wrap;

    &.open {
      .desktop-search__collapsible {
        display: flex;
      }
    }
  }

  .is-new-span {
    top: -1px;
    z-index: 10;
    border-radius: 8px;
    border: 2px solid #ff4614;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: bold;
    display: flex;
    padding: 0 3px;
    width: 32px;
    height: 17px;
    align-items: center;
    line-height: 1;
    color: #ff4614;
    background: #fff;
    margin-left: 3px;
  }

  .is-new:before {
    display: none;
  }

  &__main-row + &__collapsible {
    margin-top: 15px;
  }

  &__main-row {
    display: flex;
    width: 100%;
    flex: 0 0 100%;
    align-items: center;
    justify-content: space-between;

    .is-online:after {
      content: '';
      position: absolute;
      right: -14px !important;
      top: 3px !important;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      border: 2px solid #fff;
      background: #05ca6b;
    }

    .item-flex {
      display: flex;
      align-items: center;
    }

    .btn-filter {
      width: 100px;
      height: 36px;
      border-radius: 88px;
    }
  }

  &__collapsible {
    display: none;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      display: flex;
      width: 100%;
      align-items: center;
      height: 100%;
    }

    .sliders-custom {
      .item {
        width: 100%;
      }
    }
  }
}

label + span {
  margin-left: 5px;
}

.collapse-row {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;

  &:first-child {
    margin-top: 30px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@import '../../assets/scss/mixins';

.search-grid {
  padding-top: 15px;

  .action-page--heading {
    padding: 0;
    margin-bottom: 20px;
    overflow: hidden;
  }
}

.responsive-search {
  .is-new {
    display: flex;
  }

  .is-new-span {
    z-index: 10;
    border-radius: 8px;
    border: 2px solid #ff4614;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: bold;
    display: flex;
    padding: 0 3px;
    width: 32px;
    height: 17px;
    align-items: center;
    margin-left: 3px;
    line-height: 1;
    color: #ff4614;
    background: #fff;
  }

  .is-new:before {
    display: none !important;
  }
}

.women-desktop {
  .single-select {
    width: 260px;
    flex: 0 0 260px;
  }
}

@media (min-width: 992px) and (max-width: 1260px) {
  .desktop-search {
    font-size: 12px !important;
  }

  .btn {
    padding: 13px 12px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .search-grid,
  .responsive-box-search {
    background-color: #fff;
  }

  .responsive-box-search {
    border-radius: 8px;
  }

  .search-grid {
    border-radius: 8px;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767.98px) {
  .search-page .action-page {
    padding-top: 20px;
  }
  .responsive-search {
    position: relative;
    padding: 20px;
    background: $light-grey;
    margin: 0 20px 20px;

    &--heading {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.4;
      letter-spacing: 0.9px;
      text-align: center;
    }

    &__tools {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .responsive-search__short-search {
        order: 2;
        width: 100%;
        .form-control {
          width: 100%;
          background: none;
        }
      }
      .responsive-search__checkboxes {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-content: center;
        height: 100%;
        order: 1;
        &.collapsed {
          width: 100%;
          flex-wrap: wrap;
          .item {
            width: 50%;
            display: flex;
            margin-bottom: 15px;

            &:nth-child(even) {
              justify-content: flex-end;
            }
          }
        }
      }
    }

    &__toggling-search {
      margin-top: 25px;
      .form-control {
        width: 100%;
        background-color: transparent;
      }
      .sliders-custom {
        width: 100%;
      }
      .item {
        margin-bottom: 15px;
      }
    }
    .btn-group {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 25px;
      .btn {
        display: flex;
        height: 45px;
        align-items: center;
        justify-content: center;
        padding: 10px 30px;
        &.show-more {
          width: 45px;
          padding: 0;
          margin-left: 25px;
          .rbi {
            transform-origin: 50% 50%;
            transition: 0.3s;
          }
          &.open {
            .rbi {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .search-grid {
    &__inner {
      @include customGrid(100%, 3, 31.5%, user-preview);
    }
  }
}

@media only screen and (max-width: 400px) {
  .responsive-search {
    &__tools {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .responsive-search__checkboxes {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-content: center;
        height: 100%;
        order: 1;
        &.collapsed {
          width: 100%;
          flex-wrap: wrap;
          .item {
            width: 100%;
            display: flex;
            margin-bottom: 15px;

            &:nth-child(even) {
              justify-content: flex-start;
            }
          }
        }
      }
    }

    &__toggling-search {
      margin-top: 25px;
      .form-control {
        width: 100%;
        background-color: transparent;
      }
      .sliders-custom {
        width: 100%;
      }
      .item {
        margin-bottom: 15px;
      }
    }
    .btn-group {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 25px;
      .btn {
        display: flex;
        height: 45px;
        align-items: center;
        justify-content: center;
        padding: 10px 30px;
        &.show-more {
          width: 45px;
          padding: 0;
          margin-left: 25px;
          .rbi {
            transform-origin: 50% 50%;
            transition: 0.3s;
          }
          &.open {
            .rbi {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}
</style>
