<template>
  <div class="set-flex">
    <slot name="user" />
    <div class="news-box--item__data">
      <div class="news-box--item__responsive">
        <slot name="responsive-user" />
        <p class="news-box--item__data--info d-none d-md-none d-lg-block">
          {{ record.user.name }} {{ $t('actionPages.news.info.uploaded') }} ({{
            record.photos.length
          }}) {{ $tc('actionPages.news.info.newPhotos', record.photos.length) }}
        </p>
        <slot name="time" />
      </div>
      <p class="news-box--item__data--info d-lg-none d-md-block">
        {{ record.user.name }} {{ $t('actionPages.news.info.uploaded') }} ({{
          record.photos.length
        }}) {{ $tc('actionPages.news.info.newPhotos', record.photos.length) }}
      </p>
      <ul class="news-box--item__data--photos-box list-unstyled">
        <li
          v-for="(photo, i) in photosBefore"
          :key="photo.photo_id"
          class="guest-gallery news-box--item__data--photo item"
          @click="takePhotos(true, formattedGallery, i)"
        >
          <div class="img-wrap">
            <img :src="photo.photo_link" :alt="record.user.name.name + $t('shared.alts.photo')" />
          </div>
        </li>
      </ul>
      <button
        v-if="record.photos.length > limit"
        class="news-box--item__data--more"
        :class="{ active: open }"
        @click="open = !open"
      >
        <span>
          <span class="underline">{{ expandText }}</span>
        </span>
        <span class="rbi rbi-down-arrow" />
      </button>
    </div>
  </div>
</template>

<script>
import { GALLERY_INDEX } from '../../../mixins/utils';

export default {
  mixins: [GALLERY_INDEX],
  props: {
    record: Object,
    width: Number,
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    /**
     * Трансформировать объекты в соответствии с требованиями галереи
     *
     * @return [array]
     */
    formattedGallery() {
      return this.record.photos.map((item) => ({
        img_origin: item.photo_origin,
        photo_link: item.photo_link,
      }));
    },

    /**
     * Список Фотографий, которые будут отображаться в зависимости от лимита и кнопки "показать еще"
     * @return {array}
     */
    photosBefore() {
      if (this.record.photos.length > this.limit) {
        return this.open ? this.record.photos : this.record.photos.slice(0, this.limit);
      }
      return this.record.photos;
    },

    /**
     * Текст кнопки "показать еще"
     * @return {string}
     */
    expandText() {
      return this.open ? this.$t('actionPages.news.hide') : this.$t('actionPages.news.viewAll');
    },

    /**
     * Получить значение лимита отображения фотографийй в зависимости от ширины вьюпорта
     *
     * @return {number}
     */
    limit() {
      let tmp;
      // 16, 5, 3 - values also used in CSS grid. Depends on current display width
      if (this.width >= 992) {
        tmp = 14;
      } else if (this.width < 992 && this.width > 768) {
        tmp = 6;
      } else {
        tmp = 3;
      }
      return tmp;
    },
  },
  created() {},
  methods: {},
};
</script>
