<template>
  <div class="main-block">
    <div class="white-box action-page notification-page">
      <div class="action-page--heading gray">
        <div class="d-flexset-flex-x-between">
          <h1 class="action-page--heading__h d-flex align-items-center">
            <span>
              {{ $t('actionPages.titles.notifications') }}
            </span>
          </h1>
        </div>
        <!--        <ul class="nav nav-tabs" role="tablist">
                  <li role="presentation" class="active">
                    <a href="#all"
                       @click="switchPage('all')"
                       aria-controls="my"
                       role="tab" data-toggle="tab">
                      <span>{{ $t( 'actionPages.utils.all' ) }}</span>
                      <template>({{ notifications.all.length }})</template>
                    </a>
                  </li>
                  <li role="presentation">
                    <a href="#favorites"
                       @click="switchPage('favorites')"
                       aria-controls="me"
                       role="tab" data-toggle="tab">
                      <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.2 489.2">
                        <path
                          d="M40.8 265.4l189 189c4 4 9.4 6.2 15 6.2s11-2.2 15-6.2l188.7-188.7c26.2-26.2 40.7-61.1 40.7-98.1 0-37.1-14.4-72-40.6-98.2s-61-40.6-98.1-40.6-71.9 14.5-98.2 40.7l-7.6 7.6-7.8-7.8c-26.3-26.3-61.2-40.7-98.3-40.7-37 0-71.8 14.4-98 40.6S0 130.3 0 167.4c.1 37 14.6 71.8 40.8 98z"/>
                      </svg>
                      <span class="hidden-xs">{{ $t( 'shared.common.favourites' ) }}</span>
                      <template>({{ notifications.favorites.length }})</template>
                    </a>
                  </li>
                  <li role="presentation">
                    <a href="#bookmarks"
                       @click="switchPage('bookmarks')"
                       aria-controls="me"
                       role="tab" data-toggle="tab">
                      <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.451 490.451">
                        <path
                          d="M463 169l-138-10-52.7-128c-5-12.1-16.1-18.2-27.1-18.2s-22.1 6.1-27.1 18.2l-52.7 128-138 10c-25.6 1.4-37 34.2-17.1 51.3l107 88.3-32.8 134c-5.5 19.7 10.1 36.1 27.4 36.1 5.2 0 10.5-1.5 15.4-4.7l117-72.7 118 72.7c4.9 3.3 10.2 4.7 15.2 4.7 16.9 0 31.9-16.4 27.5-36.1l-32.8-134 108-88.3c21-17 10-50-16-51z"/>
                      </svg>
                      <span class="hidden-xs">{{ $t( 'shared.common.bookmarks' ) }}</span>
                      <template>({{ notifications.bookmarks.length }})</template>
                    </a>
                  </li>
                </ul>-->
      </div>
      <tabs :options="{ useUrlFragment: false }" cache-lifetime="0" @changed="tabChanged">
        <tab id="all" :name="titleAll">
          <div class="action-page--content-float">
            <app-item
              v-for="item in minifiedAll"
              :key="item.notification_id.$oid"
              :record="item"
              @mouseover.native="markViewed(item.notification_id.$oid, false, item)"
            />
            <div
              v-show="minifiedAll.length !== 0 && minifiedAll.length < this.allCount"
              class="button-wrap"
            >
              <lazy-load :counter="limit" @click.native="loadMore" />
            </div>

            <div v-show="showPreloader.all" class="alert-actions-wrap">
              <div class="loader--wrap">
                <div class="loader" />
              </div>
            </div>
            <p v-show="!notifications.all.length && !showPreloader.all" class="alert alert-info">
              {{ $t('actionPages.errors.noNotifications') }}
            </p>
          </div>
        </tab>
        <tab id="favorites" :name="titleFavs">
          <div class="action-page--content-float">
            <app-item
              v-for="item in minifiedFavorites"
              :key="item.notification_id.$oid"
              :record="item"
            />
            <div
              v-show="
                minifiedFavorites.length !== 0 && minifiedFavorites.length < this.favoritesCount
              "
              class="button-wrap"
            >
              <lazy-load :counter="limit" @click.native="loadMore" />
            </div>

            <div v-show="showPreloader.favorites" class="alert-actions-wrap">
              <div class="loader--wrap">
                <div class="loader" />
              </div>
            </div>
            <p
              v-show="!notifications.favorites.length && !showPreloader.favorites"
              class="alert alert-info"
            >
              {{ $t('actionPages.errors.noNotifications') }}
            </p>
          </div>
        </tab>
        <tab id="bookmarks" :name="titleBookmarks">
          <div class="action-page--content-float">
            <app-item
              v-for="item in minifiedBookmarks"
              :key="item.notification_id.$oid"
              :record="item"
            />
            <div
              v-show="
                minifiedBookmarks.length !== 0 && minifiedBookmarks.length < this.bookmarksCount
              "
              class="button-wrap"
            >
              <lazy-load :counter="limit" @click.native="loadMore" />
            </div>

            <div v-show="showPreloader.bookmarks" class="alert-actions-wrap">
              <div class="loader--wrap">
                <div class="loader" />
              </div>
            </div>
            <p
              v-show="!notifications.bookmarks.length && !showPreloader.b"
              class="alert alert-info"
            >
              {{ $t('actionPages.errors.noNotifications') }}
            </p>
          </div>
        </tab>
      </tabs>
      <!--<div class="tab-content">-->
      <!--<div role="tabpanel" class="tab-pane active" id="all">-->
      <!--<div class="action-page&#45;&#45;content-float">-->
      <!--<app-item v-for="item in minifiedAll"-->
      <!--:key="item.notification_id.$oid"-->
      <!--@mouseover.native="markViewed(item.notification_id.$oid, false, item)"-->
      <!--:record="item"></app-item>-->
      <!--<lazy-load :counter="limit"-->
      <!--v-show="minifiedAll.length && minifiedAll.length < notifications.all.length"-->
      <!--@click.native="loadMore"></lazy-load>-->
      <!--<div class="alert-actions-wrap" v-show="showPreloader.all">-->
      <!--<div class="loader&#45;&#45;wrap">-->
      <!--<div class="loader"></div>-->
      <!--</div>-->
      <!--</div>-->
      <!--<div class="alert-actions-wrap" v-show="!notifications.all.length && !showPreloader.all">-->
      <!--<p class="alert alert-info">-->
      <!--{{$t('actionPages.errors.noNotifications')}}-->
      <!--</p>-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
      <!--<div role="tabpanel" class="tab-pane" id="favorites">-->
      <!--<div class="action-page&#45;&#45;content-float">-->
      <!--<app-item v-for="item in minifiedFavorites" :key="item.notification_id.$oid" :record="item"></app-item>-->
      <!--<lazy-load :counter="limit"-->
      <!--v-show="minifiedFavorites.length && minifiedFavorites.length < notifications.favorites.length"-->
      <!--@click.native="loadMore"></lazy-load>-->
      <!--<div class="alert-actions-wrap" v-show="showPreloader.favorites">-->
      <!--<div class="loader&#45;&#45;wrap">-->
      <!--<div class="loader"></div>-->
      <!--</div>-->
      <!--</div>-->
      <!--<div class="alert-actions-wrap" v-show="!notifications.favorites.length && !showPreloader.favorites">-->
      <!--<p class="alert alert-info">-->
      <!--{{$t('actionPages.errors.noNotifications')}}-->
      <!--</p>-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
      <!--<div role="tabpanel" class="tab-pane" id="bookmarks">-->
      <!--<div class="action-page&#45;&#45;content-float">-->
      <!--<app-item v-for="item in minifiedBookmarks" :key="item.notification_id.$oid" :record="item"></app-item>-->
      <!--<lazy-load :counter="limit"-->
      <!--v-show="minifiedBookmarks.length && minifiedBookmarks.length < notifications.bookmarks.length"-->
      <!--@click.native="loadMore"></lazy-load>-->
      <!--<div class="alert-actions-wrap" v-show="showPreloader.bookmarks">-->
      <!--<div class="loader&#45;&#45;wrap">-->
      <!--<div class="loader"></div>-->
      <!--</div>-->
      <!--</div>-->
      <!--<div class="alert-actions-wrap" v-show="!notifications.bookmarks.length && !showPreloader.b">-->
      <!--<p class="alert alert-info">-->
      <!--{{$t('actionPages.errors.noNotifications')}}-->
      <!--</p>-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
    </div>
  </div>
</template>

<script>
import uniqBy from 'lodash/uniqBy';
import Item from './notificationPage/ItemTemplate.vue';
import LazyLoad from './parts/LoadMore.vue';

export default {
  components: {
    appItem: Item,
    LazyLoad,
  },
  data() {
    return {
      showPreloader: {
        all: true,
        favorites: true,
        bookmarks: true,
      },
      allCount: 0,
      favoritesCount: 0,
      bookmarksCount: 0,
      limit: 30,
      searchState: false,
      activePage: 'all',
      unread: 0,
      notifications: {
        all: [],
        bookmarks: [],
        favorites: [],
      },
      params: {
        offsetAll: 0,
        offsetBookmarks: 0,
        offsetFavorites: 0,
      },
    };
  },
  computed: {
    titleAll() {
      return `<span>${this.$t('actionPages.utils.all')}</span>
							<span class="count">(${this.allCount})</span>`;
    },
    titleFavs() {
      return `<span class="rbi rbi-heart d-lg-none d-md-none d-sm-inline"></span>
              <span class=" d-none d-sm-none d-md-inline d-lg-inline">${this.$t(
                'shared.common.favourites'
              )}</span>
            <span class="count">(${this.favoritesCount})</span>`;
    },
    titleBookmarks() {
      return `<span class="rbi rbi-star d-lg-none d-md-none d-sm-inline"></span>
              <span class="d-none d-sm-none d-md-inline d-lg-inline">${this.$t(
                'shared.common.bookmarks'
              )}</span>
              <span class="count">(${this.bookmarksCount})</span>`;
    },

    width() {
      return this.$store.getters.deviceWidth;
    },
    /**
     * Получить ограниченное кол-во записей в зависимости от допустимого лимита
     *
     * @return {array}
     */
    minifiedAll() {
      return this.notifications.all;
    },
    /**
     * Получить ограниченное кол-во записей в зависимости от допустимого лимита
     *
     * @return {array}
     */
    minifiedBookmarks() {
      return this.notifications.bookmarks;
    },
    /**
     * Получить ограниченное кол-во записей в зависимости от допустимого лимита
     *
     * @return {array}
     */
    minifiedFavorites() {
      return this.notifications.favorites;
    },
  },
  watch: {
    /**
     * Отметить просмотренными записи, если заходим на моб. устройстве
     * @param v
     */
    width(v) {
      if (v && v < 1200) {
        this.markViewed('', true);
      }
    },

    /**
     * Загрузить данные в зависимости от просматриваемой вкладки
     *
     * @param val {string}
     */
    activePage(val) {
      switch (val) {
        case 'all':
          this.loadAll();
          break;
        case 'bookmarks':
          this.loadBookmarks();
          break;
        case 'favorites':
          this.loadFavorites();
          break;
      }
    },
  },
  mounted() {
    if (this.width && this.width < 1200) {
      this.markViewed('', true);
    }
    document.title = this.$t('actionPages.titles.notifications');
    this.loadAll();
    this.loadFavorites();
    this.loadBookmarks();
  },
  methods: {
    tabChanged(selectedTab) {
      switch (selectedTab.tab.id) {
        case 'all':
          this.switchPage('all');
          this.params.offsetAll = 0;
          this.notifications.all = [];
          break;
        case 'favorites':
          this.switchPage('favorites');
          this.params.offsetBookmarks = 0;
          this.notifications.favorites = [];
          break;
        case 'bookmarks':
          this.switchPage('bookmarks');
          this.params.offsetFavorites = 0;
          this.notifications.bookmarks = [];
          break;
        default:
      }
    },

    /**
     * Отлавливаем переключение между вкладками
     *
     * @param val {string}: all, bookmarks, favorites
     */
    switchPage(val) {
      this.activePage = val;
    },

    /**
     * Загрузить уведомления от всех типов пользователей
     */
    loadAll() {
      this.showPreloader.all = true;
      this.$http
        .post('v1/notification/load', {
          access_token: window.localStorage['access-token'],
          limit: this.limit,
          offset: this.params.offsetAll,
        })
        .then((response) => {
          const unreadFiltered = response.body.unread;
          this.unread = unreadFiltered.length;
          this.allCount = response.body.count;
          const readFiltered = response.body.readed;
          readFiltered.forEach((item) => (item.seen = true));
          this.notifications.all = uniqBy(
            this.notifications.all.concat(unreadFiltered.concat(readFiltered)),
            (e) => e.notification_id.$oid
          );

          this.showPreloader.all = false;
        });
    },

    /**
     * Показать больше записей на вкладке
     */
    loadMore() {
      switch (this.activePage) {
        case 'all':
          this.params.offsetAll += this.limit;
          this.loadAll();
          break;
        case 'bookmarks':
          this.params.offsetBookmarks += this.limit;
          this.loadBookmarks();
          break;
        case 'favorites':
          this.params.offsetFavorites += this.limit;
          this.loadFavorites();
          break;
      }
    },

    /**
     * Загрузить уведомления, относящиеся к закладкам
     */
    loadBookmarks() {
      this.showPreloader.bookmarks = true;
      this.$http
        .post('v1/notification/load/from/bookmarks', {
          access_token: window.localStorage['access-token'],
          limit: this.limit,
          offset: this.params.offsetBookmarks,
        })
        .then((response) => {
          const readFiltered = response.body.readed;
          this.bookmarksCount = response.body.count;
          readFiltered.forEach((item) => (item.seen = true));

          this.notifications.bookmarks = uniqBy(
            this.notifications.bookmarks.concat(readFiltered),
            (e) => e.notification_id.$oid
          );

          this.showPreloader.bookmarks = false;
        });
    },

    /**
     * Загрузить уведомления, относящиеся к фаворитам
     */
    loadFavorites() {
      this.showPreloader.favorites = true;
      this.$http
        .post('v1/notification/load/from/favorites', {
          access_token: window.localStorage['access-token'],
          limit: this.limit,
          offset: this.params.offsetFavorites,
        })
        .then((response) => {
          const readFiltered = response.body.readed;
          readFiltered.forEach((item) => (item.seen = true));
          this.favoritesCount = response.body.count;
          this.notifications.favorites = uniqBy(
            this.notifications.favorites.concat(readFiltered),
            (e) => e.notification_id.$oid
          );

          this.showPreloader.favorites = false;
        });
    },

    /**
     * Отметить записи как просмотренные
     *
     * @param id {number} ИД пользователя
     * @param all {boolean} true, если хотим отметить всех
     * @param obj {object} объект с записью
     */
    markViewed(id, all, obj) {
      if ((id && !obj.seen) || all) {
        this.$http
          .post('v1/notification/mark', {
            access_token: window.localStorage['access-token'],
            notification: id || '',
            all: all || false,
          })
          .then((response) => {
            if (obj) {
              response.body.status ? (obj.seen = true) : '';
            } else {
              // this.loadAll();
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
