<template>
  <div v-show="users.length" class="slider-new-members users-block">
    <div class="contain-edit">
      <h2 class="title-profile edit-caption">
        {{ $t('shared.headers.newMembers') }}
      </h2>
      <div class="slider-nav">
        <button slot="button-prev" class="nav-btn prev-btn new-users-prev" />
        <button slot="button-next" class="nav-btn next-btn new-users-next" />
      </div>
    </div>
    <div class="slider-out">
      <!--<div class="visited-profiles visible-xs d-lg-none">-->
      <!--<div class="flexblock">-->
      <!--<app-user-preview class="link" :key="user.user_id" :column="true" :user="user" v-for="user in users"></app-user-preview>-->
      <!--</div>-->
      <!--</div>-->
      <div class="swiper-container s4">
        <swiper :options="opts">
          <swiper-slide v-for="user in users" :key="user.user_id">
            <app-user-preview :column="true" :disabled-props="user.disabled" :user="user" />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import UserPreview from '../UserPreview.vue';
import { PHOTO_SRC } from '../../../mixins/utils';
import { NEW_USERS } from '../../../mixins/types/parts';
import { PREPARE_DATA } from '../../../mixins/userActions';

export default {
  components: {
    appUserPreview: UserPreview,
  },
  mixins: [PHOTO_SRC, PREPARE_DATA, NEW_USERS],
  data() {
    return {
      opts: {
        navigation: {
          prevEl: `.new-users-prev`,
          nextEl: `.new-users-next`,
        },
        slidesPerView: 4,
        paginationClickable: true,
        observer: true,
        observeParents: true,
        breakpoints: {
          992: {
            slidesPerView: 5,
          },
          768: {
            slidesPerView: 3,
          },
        },
        notNextTick: true,
      },
      limit: 20,
    };
  },
  computed: {
    userUpdated() {
      return this.$store.getters.user.update;
    },
    /**
     * получить нужное кол-во пользователей для отображения в зависимости от ширины вьюпорта
     * @return {Array}
     */
    users() {
      return this.width <= 768
        ? this.$store.getters.recentlyJoinedUsers.slice(0, 8)
        : this.$store.getters.recentlyJoinedUsers.slice(0, 16);
    },
    width() {
      return this.$store.getters.deviceWidth;
    },
  },
  watch: {
    /**
     * спросить данные для слайдера, когда загрузили профиль
     *
     * @param v
     */
    userUpdated() {
      //				if ( !this.asked ) {
      this.askData();
      //				}
    },
    /**
     * инициализация свипера
     * проверка привилегий для взаимодействия с пользователями
     *
     * @param v
     */
    users(v) {
      if (v.length) this.initSwiper();
      if (v.length && !this.usersUpdated) {
        this.massCheckPrivileges(v);
      }
    },
    sex() {},
  },
  mounted() {
    //			this.asked = false;
    //			this.askData();
  },
  methods: {
    /**
     * инициализация плагина с галереей
     */
    initSwiper() {},
  },
  beforeRouteLeave(to, from, next) {
    this.asked = false;
    next();
  },
};
</script>

<style scoped lang="scss">
.slider-nav {
  background: #ffffff;
  position: relative;
  padding: 0 0 0 10px;
  display: flex;
  margin-top: 2px;
  .nav-btn {
    width: 25px;
    height: 25px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    margin-right: 20px;
    opacity: 0.5;
    transition: 0.3s;

    &:hover {
      opacity: 1;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .next-btn {
    background-image: url('/static/images/svg/icons/right-arrow.svg');
  }

  .prev-btn {
    background-image: url('/static/images/svg/icons/left-arrow.svg');
  }

  .swiper-button-disabled {
    opacity: 0.2;

    &:hover {
      opacity: 0.2;
    }
  }
}
.slider-new-members .slider-out {
  margin: 0;
}
.s4 .swiper-slide {
  padding: 0;
}
</style>
