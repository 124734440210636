<template>
  <div class="main-block">
    <div class="white-box action-page news-page">
      <div class="action-page--heading gray">
        <div class="d-flexset-flex-x-between">
          <h1 class="action-page--heading__h d-flex set-flex-y-center">
            <span>
              {{ $t('actionPages.titles.news') }}
            </span>
            <span v-show="count" class="label label-primary">{{ count }}</span>
          </h1>
        </div>
      </div>

      <tabs cache-lifetime="0" :options="{ useUrlFragment: false }">
        <tab id="all" :name="titleAll">
          <div class="action-page--grid news-box">
            <div v-show="showPreloader.all" class="loader--wrap">
              <div class="loader" />
            </div>
            <birthday-template v-if="birthday.all.length" :width="width" :users="birthday.all" />
            <div
              v-for="(record, index) in news.all"
              :key="`nball-record-${index}`"
              class="news-box--item"
            >
              <item :record="record" :width="width" />
            </div>
            <div
              v-show="!showPreloader.all && news.all.length >= limit && showLoadBtn.all"
              class="button-wrap"
            >
              <button
                type="button"
                class="btn btn-lazy-load"
                @click="loadNews('all', news.all.length)"
              >
                <span class="d-flex align-items-center set-flex-x-center">
                  {{ $t('shared.btn.lazyLoad', [limit]) }}
                </span>
              </button>
            </div>

            <div v-show="!showLoadBtn.all" class="alert-actions-wrap">
              <div class="alert alert-info">
                <p>{{ $t('actionPages.errors.noNews') }}</p>
              </div>
            </div>
          </div>
        </tab>
        <tab id="favorites" :name="titleFavs">
          <div class="action-page--grid news-box">
            <div v-show="showPreloader.favorite" class="loader--wrap">
              <div class="loader" />
            </div>
            <birthday-template
              v-if="birthday.favorite.length"
              :width="width"
              :users="birthday.favorite"
            />
            <div
              v-for="(record, index) in news.favorite"
              :key="`nbf-record-${index}`"
              class="news-box--item"
            >
              <item :record="record" :width="width" />
            </div>
            <div
              v-show="
                !showPreloader.favorite && news.favorite.length >= limit && showLoadBtn.favorite
              "
              class="button-wrap"
            >
              <button
                type="button"
                class="btn btn-lazy-load"
                @click="loadNews('favorite', news.favorite.length)"
              >
                <span class="d-flex align-items-center set-flex-x-center">
                  {{ $t('shared.btn.lazyLoad', [limit]) }}
                </span>
              </button>
            </div>

            <div v-show="!showLoadBtn.favorite" class="alert-actions-wrap">
              <div class="alert alert-info">
                <p>{{ $t('actionPages.errors.noNews') }}</p>
              </div>
            </div>
          </div>
        </tab>
        <tab id="bookmarks" :name="titleBookmarks">
          <div class="action-page--grid news-box">
            <div v-show="showPreloader.bookmark" class="loader--wrap">
              <div class="loader" />
            </div>
            <birthday-template
              v-if="birthday.bookmark.length"
              :width="width"
              :users="birthday.bookmark"
            />
            <div
              v-for="(record, index) in news.bookmark"
              :key="`nb-record-${index}`"
              class="news-box--item"
            >
              <item :record="record" :width="width" />
            </div>
            <div
              v-show="
                !showPreloader.bookmark && news.bookmark.length >= limit && showLoadBtn.bookmark
              "
              class="button-wrap"
            >
              <button
                type="button"
                class="btn btn-lazy-load"
                @click="loadNews('bookmark', news.bookmark.length)"
              >
                <span class="d-flex align-items-center set-flex-x-center">
                  {{ $t('shared.btn.lazyLoad', [limit]) }}
                </span>
              </button>
            </div>
            <div v-show="!showLoadBtn.bookmark" class="alert-actions-wrap">
              <div class="alert alert-info">
                <p>{{ $t('actionPages.errors.noNews') }}</p>
              </div>
            </div>
          </div>
        </tab>
      </tabs>

      <!--      <div class="tab-content">
              <div role="tabpanel" class="tab-pane" id="favorites">
                <div class="action-page&#45;&#45;content-float news-box">
                  <div class="loader&#45;&#45;wrap" v-show="showPreloader.favorite">
                    <div class="loader"></div>
                  </div>
                  <birthday-template :width="width" :users="birthday.favorite"
                                     v-if="birthday.favorite.length"></birthday-template>
                  <div class="news-box&#45;&#45;item" v-for="record in news.favorite">
                    <item :record="record" :width="width"></item>
                  </div>
                  <button type="button"
                          v-show="!showPreloader.favorite && news.favorite.length >= limit && showLoadBtn.favorite"
                          @click="loadNews('favorite', news.favorite.length)"
                          class="btn btn-lazy-load btn btn-primary transparent">
                                          <span class="d-flex align-items-center set-flex-x-center">
                                              {{ $t( 'shared.btn.lazyLoad', [ limit ] ) }} <i
                                            class="fa fa-circle-o-notch" :class="{'fa-spin': loading}" aria-hidden="true"></i>
                                          </span>
                  </button>
                  <div class="alert-actions-wrap" v-show="!showLoadBtn.favorite">
                    <div class="alert alert-info">
                      <p>{{$t('actionPages.errors.noNews')}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div role="tabpanel" class="tab-pane" id="bookmarks">
                <div class="action-page&#45;&#45;content-float news-box">
                  <div class="loader&#45;&#45;wrap" v-show="showPreloader.bookmark">
                    <div class="loader"></div>
                  </div>
                  <birthday-template :width="width" :users="birthday.bookmark"
                                     v-if="birthday.bookmark.length"></birthday-template>
                  <div class="news-box&#45;&#45;item" v-for="record in news.bookmark">
                    <item :record="record" :width="width"></item>
                  </div>
                  <button type="button"
                          v-show="!showPreloader.bookmark && news.bookmark.length >= limit && showLoadBtn.bookmark"
                          @click="loadNews('bookmark', news.bookmark.length)"
                          class="btn btn-lazy-load btn btn-primary transparent">
                                          <span class="d-flex align-items-center set-flex-x-center">
                                              {{ $t( 'shared.btn.lazyLoad', [ limit ] ) }} <i
                                            class="fa fa-circle-o-notch" :class="{'fa-spin': loading}" aria-hidden="true"></i>
                                          </span>
                  </button>
                  <div class="alert-actions-wrap" v-show="!showLoadBtn.bookmark">
                    <div class="alert alert-info">
                      <p>{{$t('actionPages.errors.noNews')}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>-->
    </div>
    <preview-modal />
  </div>
</template>

<script>
import PreviewModal from '../Shared/modals/PreviewModal.vue';
import ItemTemplate from './newsPage/ItemTemplate.vue';
import BirthdayTemplate from './newsPage/BirthdayTemplate.vue';

export default {
  components: {
    item: ItemTemplate,
    BirthdayTemplate,
    PreviewModal,
  },
  data() {
    return {
      showPreloader: {
        all: true,
        bookmark: true,
        favorite: true,
      },
      showLoadBtn: {
        all: true,
        bookmark: true,
        favorite: true,
      },
      count: 0,
      limit: 20,
      news: {
        all: [],
        bookmark: [],
        favorite: [],
      },
      birthday: {
        all: [],
        bookmark: [],
        favorite: [],
      },
      loading: false,
      width: 0,
    };
  },
  computed: {
    titleAll() {
      return `<span>${this.$t('actionPages.utils.all')}</span>`;
    },
    titleFavs() {
      return `              <span class="rbi rbi-heart d-lg-none d-md-none d-sm-inline"></span>
              <span class=" d-none d-sm-none d-md-inline d-lg-inline">${this.$t(
                'shared.common.favourites'
              )}</span>`;
    },
    titleBookmarks() {
      return `              <span class="rbi rbi-star d-lg-none d-md-none d-sm-inline"></span>
              <span class="d-none d-sm-none d-md-inline d-lg-inline">${this.$t(
                'shared.common.bookmarks'
              )}</span>`;
    },
  },
  created() {
    // eslint-disable-next-line no-undef
    this.width = $(window).width();
    // eslint-disable-next-line no-undef
    $(window).resize(() => {
      // eslint-disable-next-line no-undef
      this.width = $(window).width();
    });
  },
  mounted() {
    document.title = this.$t('actionPages.titles.news');
    this.loadNews('all');
    this.loadBirthdays('bookmark');
    this.loadCount();
    this.loadNews('favorite');
    this.loadNews('bookmark');
    this.loadBirthdays('all');
    this.loadBirthdays('favorite');
  },
  methods: {
    /**
     * Загрузать список новостей
     *
     * @param type {string} - all, bookmarks, favorites
     * @param offset {number} - начиная с какой записи загружать
     * @param limit {numbre} - сколько записей загружать
     */
    loadNews(type, offset, limit) {
      this.loading = true;
      this.showPreloader[type] = true;
      const resource = this.$resource('v1/news{/type}');
      resource
        .save(
          { type },
          {
            access_token: window.localStorage['access-token'],
            offset: offset || 0,
            limit: limit || this.limit,
          }
        )
        .then(
          (response) => {
            if (response.body.status) {
              this.news[type] = this.news[type].concat(response.body.result);
              this.loading = false;
              if (!response.body.result.length) this.showLoadBtn[type] = false;
            } else {
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.someErr'),
                info: 'load news',
              });
            }
            this.showPreloader[type] = false;
          },
          () => {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.someErr'),
              info: 'load news',
            });
            this.showPreloader[type] = false;
          }
        );
    },

    /**
     * Загрузить список пользователей, у которых сегодня день рождения
     *
     * @param type {string} - all, bookmarks, favorites
     */
    loadBirthdays(type) {
      this.$http
        .post('v1/news/birthday', {
          access_token: window.localStorage['access-token'],
          user_connection: type,
        })
        .then((response) => (this.birthday[type] = response.body.result));
    },

    /**
     * Загрузить счётчик с кол-вом записей за сегодня
     */
    loadCount() {
      this.$http
        .post('v1/news/all-count', {
          access_token: window.localStorage['access-token'],
        })
        .then((response) => (this.count = +response.body.result));
    },
  },
  /**
   * Закрыть галерею при покидании страницы
   */
  beforeRouteLeave(to, from, next) {
    this.$store.commit('updateGallery', {
      open: false,
      index: 0,
      hideWatermark: false,
      photos: [],
    });
    next();
  },
};
</script>

<style lang="scss" scoped>
p.text-center {
  padding: 10px;
}

.button-wrap {
  border-top: none;
}
.action-page--grid {
  padding-bottom: 20px;
}
</style>
