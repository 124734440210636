<template>
  <div class="action-page--content-float__item d-flex align-items-center">
    <component :is="record.type">
      <router-link
        slot="ava"
        :to="{name: 'profile', params: {id: record.sender.id}}"
        class="avatar-link"
      >
        <img
          :src="setPhotoSource(record.sender.avatar.img_small)"
          :alt="record.sender.name + $t('shared.alts.ava')"
          class="user-small-avatar"
        >
      </router-link>
      <router-link
        slot="name"
        :to="{name: 'profile', params: {id: record.sender.id}}"
      >
        <span class="name">{{ record.sender.name }}</span>
      </router-link>
    </component>
    <p class="hidden-xs">
      {{ date }}
    </p>
  </div>
</template>

<script>
import moment from 'moment';
import TemplateWink from './TemplateWink.vue';
import TemplateFavorite from './TemplateFavorite.vue';
import TemplateGuest from './TemplateGuest.vue';
import TemplateLetter from './TemplateLetter.vue';
import TemplateBookmark from './TemplateBookmark.vue';
import { PHOTO_SRC } from '../../../mixins/utils';

export default {
  components: {
    wink: TemplateWink,
    favorites: TemplateFavorite,
    guest: TemplateGuest,
    inbox: TemplateLetter,
    bookmarks: TemplateBookmark,
  },
  mixins: [PHOTO_SRC],
  props: {
    record: Object,
  },
  data() {
    return {};
  },
  computed: {
    /**
			 * Получить отображенме даты, основываясь на дате создания записи
			 *
			 * @return {string}
			 */
    date() {
      return moment(this.record.created * 1000).format('Do MMM, HH:mm');
    },
  },
  methods: {},
};
</script>

<style >
</style>
